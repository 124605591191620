import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getModeParm }  from './getModeParm';
import { BOOK_TYPE_RESOURCES, BOOK_TYPE_EXTRAS } from '../constants.js';
import { getBible } from './localSettings';

export const GetAllCards = ( bookId, resourceId, cardType, bookType, isOBTHelps ) => {
    // console.log('cardType:  ' + cardType);
    const biblesPath = isOBTHelps?'helps' : 'bibles';
    const extrasPath = process.env.REACT_APP_EXTRAS_PATH?process.env.REACT_APP_EXTRAS_PATH:'bibles';
    const isExtras = (bookType === BOOK_TYPE_EXTRAS);
    const { bibleId, isBibleBrain } = getBible();
    let cardTypePlural = (cardType === BOOK_TYPE_RESOURCES)?"movements":`${cardType}s`
    // console.log("GetAllCards: cardTypePlural:", cardTypePlural);
    let sourceId = (cardType === BOOK_TYPE_RESOURCES) ? resourceId : bookId;
    let cards = [];
    let {isMVP, modeParm} = getModeParm();
    let thisParm = "?sections=false"
    if (modeParm !== "") {
      thisParm = `${modeParm}&sections=false`
    }
    let url = '';
    const isEnabled = !!bibleId && !!sourceId && !!cardTypePlural; // Only run when URL parms are truthy

    if (isExtras) {
      // Use "General" endpoint for extras
      url = `${process.env.REACT_APP_BASE_URL}/${extrasPath}/${bibleId}/general/${sourceId}/${cardTypePlural}${thisParm}`
    } else {
      if (isBibleBrain) {
        //url = `${process.env.REACT_APP_PROXY_URL}/${cardTypePlural}/${bibleId}/${bookId}`
        url = `${process.env.REACT_APP_PROXY_URL}/bibles/${bibleId}/${sourceId}/${cardTypePlural}${thisParm}`
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/${biblesPath}/${bibleId}/${sourceId}/${cardTypePlural}${thisParm}`
      }
    }
    // console.log("GetAllCards url", url);
    const { data, isPending, isError, error } = useQuery({
        queryKey: [bibleId,bookType,sourceId,cardType],
        queryFn: () => axiosClient.get(url).then((res) => res.data),
        enabled: isEnabled,
        staleTime: 1000 * 60 * 30, // cache for 30 minutes
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });  

  // if (!isPending && data && data.data[0] && data.data[0][cardTypePlural]) {    
  //   cards = data.data[0][cardTypePlural];    
  //   // console.log(cards);
  // }
  if (!isPending) {
    // console.log("GetAllCards - data", data);
    
    if (data && data.data) {
      if (isMVP ) {
        if (data.data[0] && data.data[0][cardTypePlural]) {
          cards = data.data[0][cardTypePlural];
        }
      } else {
        if (data.data[cardTypePlural]) {
          cards = data.data[cardTypePlural]      
          if (cards && cards.length > 1) {
            cards.sort((a, b) => a.movement - b.movement);
          }
        }
      }
    }
  }
  // console.log("GetAllCards - cards", cards);
  return { sourceId, cards, isPending, isError, error }
}
