// listFunctions.js

import { buildSectionTitle, filterPassages, filterPassageNames, concatenateAudioNotes, getSectionVerseRange } from "./filterPassages.js";
import { mapCategory } from "./categoryFunctions.js";
import { getTime } from "./getTime.js";

/**
   * 
   * * pushSection: Updates passageList and notesList arrays
   * 
   * @param {*} card : single card to push onto the list
   * @param {array} passageList : list of sections of scripture passages
   * @param {array} notesList : list of audio notes assocaited with each passage
   * @param {integer} cardTrackIndex : index of the current card - order it should appear in the playlist
   * @param {string} bibleId
   * @param {string} bookId
   * @param {string} parent : type of the parent (chapters, movements)
   * @param {string} parentCode : id of the parent chapter or movement 
   * @param {string} parentImage : image of the parent - to be used as default for child image if none provided from source
   * @param {boolean} isOBTHelps : true for obthelps and false for akuo - used to deterimne how to build the section title - obthelps wants to include the verse range
   * 
   * List cards will track playable and downloadable items and will be formatted as 
          {
              "key": unique key for the book
              "id": id for this type of card as returned by the API
              "index": index in the playlist,
              "parent": ${parent}-${parentCode}  (parent is chapters or movements and parentCode is the number)
              "section": section index in the playlist (may not be used)
              "sectionId": section id
              "title": title / name
              "passageAudio": list of audio files - filtered out chapter/title categories if disabled
              "passageAudioUnfiltered": list of all audio files regardless of category filters
              "passageFileName": list of audio file names - filtered out chapter/title categories if disabled
              "passageFileNameUnfiltered": list of all audio file names regardless of category filters
              "image": image file,
              "audioTitle": title audio,
              "passage": if note, this is the passage number
              "category": category name for notes,
              "categoryId": category id for notes,
              "color": color for note category,
              "duration": play duration
              "show":true/false to show this card - used to filter notes by categories
            }
**/
export const pushSection = (card,passageList,notesList,cardTrackIndex,bibleId,bookId,parent,parentCode,parentImage,isOBTHelps) => {
      let placeholderImage = process.env.REACT_APP_PLACEHOLDER_IMAGE;
      // console.log("pushSections", passageList, card);
      let thisKey = `${bookId}-${parent}-${parentCode}-Section-${card.section}`;
      // console.log("pushSections passage key = " + thisKey);
      
      if (card.passages === undefined || card.passages === null || card.passages.length === 0) {
        
        // Special processing to treat notes as passages if there is no passage
        if (card.audio_notes && card.audio_notes.length > 0) {
          //console.log("Have notes without a passage:", card.section, card.audio_notes)
          let passageNotes = JSON.parse(JSON.stringify(card.audio_notes));
          if (passageNotes.length > 0 ) {
            let note = passageNotes.shift();  // strip off the first note 
            // console.log("pushSection mapCategory:", note)           
            let categoryNote = mapCategory(bibleId,note);
            let showCard = categoryNote?categoryNote.toggle:true;
            passageList.push({
              "key": thisKey,
              "id":note.id,
              "index":cardTrackIndex,
              "parent":`${parent}-${parentCode}`,
              "section":card.section,
              "sectionId":note.id,
              "title":note.title,
              "passageAudio":[note.audio[0]?.url?note.audio[0].url:null],
              "passageAudioUnfiltered":[note.audio[0]?.url?note.audio[0].url:null],  // nothing to filter
              "passageFileName":[note.audio[0]?.audio_filename?note.audio[0].audio_filename:null],              
              "passageFileNameUnfiltered":[note.audio[0]?.audio_filename?note.audio[0].audio_filename:null], // nothing to filter
              "image":note.images[0]?.url?note.images[0].url:mapCategory(bibleId,note)?.images[0]?mapCategory(bibleId,note)?.images[0].url?mapCategory(bibleId,note)?.images[0].url:placeholderImage:placeholderImage,
              "audioTitle":note.title_audio?note.title_audio[0]?.url?note.title_audio[0].url:null:null,
              "passage":note.passage,
              "category":note.note_category,
              "categoryId":note.note_category_id,
              "color":mapCategory(bibleId,note)?.color,
              "duration":note.audio[0]?.duration?getTime(note.audio[0].duration):null,
              "show":showCard,
              "scriptureAddress": null,
              "imageOverlay":null
            });
            // If more notes after the first one, treat the rest as notes for this section
            if (passageNotes.length > 0 ) {
              // console.log("pushSection - notes without a passage - have additional notes after the first", passageNotes);
              passageNotes.forEach(note => {              
                thisKey=`${bookId}-${parent}-${parentCode}-Section-${card.section}-Note-${note.id?note.id:note.passage}`;
                // console.log("pushSections note key = " + thisKey);
                // console.log("pushSections note" , note);
                cardTrackIndex++;
                let categoryNote = mapCategory(bibleId,note);
                let showCard = categoryNote?categoryNote.toggle:true;
                notesList.push({
                  "key": thisKey,
                  "id":note.id,
                  "index":cardTrackIndex,
                  "parent":`${parent}-${parentCode}`,
                  "section":card.section,
                  "sectionId":card.id,  // parent is section
                  "title":note.title,
                  "passageAudio":[note.audio[0]?.url?note.audio[0].url:null],
                  "passageAudioUnfiltered":[note.audio[0]?.url?note.audio[0].url:null],  // nothing to filter
                  "passageFileName":[note.audio[0]?.audio_filename?note.audio[0].audio_filename:null],
                  "passageFileNameUnfiltered":[note.audio[0]?.audio_filename?note.audio[0].audio_filename:null], // nothing to filter
                  "image":note.images[0]?.url?note.images[0].url:mapCategory(bibleId,note)?.images[0]?mapCategory(bibleId,note)?.images[0].url?mapCategory(bibleId,note)?.images[0].url:placeholderImage:placeholderImage,
                  "audioTitle":note.title_audio?note.title_audio[0]?.url?note.title_audio[0].url:null:null,
                  "passage":note.passage,
                  "category":note.note_category,
                  "categoryId":note.note_category_id,
                  "color":mapCategory(bibleId,note)?.color,
                  "duration":note.audio[0]?.duration?getTime(note.audio[0].duration):null,
                  "show":showCard
                });
                  // console.log("notesList:", notesList);
              })
            }
          }
        }
      } else {
        // Save all section passages and notes for later reference
        passageList.push({
          "key": thisKey,
          "id":card.id,
          "index":cardTrackIndex,
          "parent":`${parent}-${parentCode}`,
          "section":card.section,
          "sectionId":card.id,
          "title":buildSectionTitle(isOBTHelps,card),
          "passageAudio":filterPassages(card.passages),
          "passageAudioUnfiltered":filterPassages(card.passages, true),  // unfiltered includes titles and chapters regardless of category settings
          "passageFileName":filterPassageNames(card.passages),
          "passageFileNameUnfiltered":filterPassageNames(card.passages, true),
          "image":card.images[0]?card.images[0].url?card.images[0].url:parentImage:parentImage,
          "audioTitle":card.title_audio?card.title_audio[0]?.url?card.title_audio[0]?.url:null:null,
          "passage":null,
          "category":null,
          "categoryId":null,
          "color":null,
          "duration":getTotalDuration(card.passages),  // duration must be pulled from each passage in passages[] and summed 
          "show":true,
          "scriptureAddress": getSectionVerseRange(card),
          "imageOverlay":card.images[0]?(card.images[0].url?false:true):true
        });
      
        // console.log("passageList:",passageList);
        let newPassages = concatenateAudioNotes(card);
        if (card.passages && newPassages && newPassages.length > 0) {
          
          newPassages.forEach(passage => {        
            thisKey = `${bookId}-${parent}-${parentCode}-Section-${card.section}-Passage-${passage.id?passage.id:passage.passage}`;                             
            if (passage.audio_notes && passage.audio_notes.length>0) {
              
              passage.audio_notes.forEach(note => {              
                thisKey=`${bookId}-${parent}-${parentCode}-Section-${card.section}-Note-${note.id?note.id:note.passage}`;
                // console.log("pushSections note key = " + thisKey);
                // console.log("pushSections note" , note);
                cardTrackIndex++;
                let showCard = mapCategory(bibleId,note)?.toggle;
                // console.log("pushSection note, duration:", note, note.audio[0]?.duration)
                //let showCard = true;
                notesList.push({
                  "key": thisKey,
                  "id":note.id,
                  "index":cardTrackIndex,
                  "parent":`${parent}-${parentCode}`,
                  "section":card.section,
                  "sectionId":card.id,  // parent is section
                  "title":note.title,
                  "passageAudio":[note.audio[0]?.url?note.audio[0].url:null],
                  "passageAudioUnfiltered":[note.audio[0]?.url?note.audio[0].url:null], // nothing to filter
                  "passageFileName":[note.audio[0]?.audio_filename?note.audio[0].audio_filename:null],
                  "passageFileNameUnfiltered":[note.audio[0]?.audio_filename?note.audio[0].audio_filename:null], // nothing to filter
                  "image":note.images[0]?.url?note.images[0].url:mapCategory(bibleId,note)?.images[0]?mapCategory(bibleId,note)?.images[0].url?mapCategory(bibleId,note)?.images[0].url:placeholderImage:placeholderImage,
                  "audioTitle":note.title_audio?note.title_audio[0]?.url?note.title_audio[0].url:null:null,
                  "passage":note.passage,
                  "category":note.note_category,
                  "categoryId":note.note_category_id,
                  "color":mapCategory(bibleId,note)?.color,
                  "duration":note.audio[0]?.duration?getTime(note.audio[0].duration):null,
                  "show":showCard
                });
                  // console.log("notesList:", notesList);
              })
            }
          })
        }
      }

    // console.log("pushSections end - passageList", passageList);
    // console.log("pushSections end- notesList", notesList);
  }

  /**
   * pushMovementNote - Update passageList - Treat movement level notes as passages instead of notes
   * 
   * @param {*} notes : array of audio notes returned by API
   * 
   */
export const pushMovementNote = (note,passageList,cardTrackIndex,bibleId,bookId,parent,parentCode,parentImage) => {
      // console.log("pushMovementNote", passageList, note);
      let sectionIndex=0;
      let thisKey = `${bookId}-${parent}-${parentCode}-Section-${sectionIndex}-Note-${note.id?note.id:note.passage}`;
      // console.log("pushMovementNote key = " + thisKey);
      let showCard = true;
      passageList.push({
        "key": thisKey,
        "id":note.id,
        "index":cardTrackIndex,
        "parent":`${parent}-${parentCode}`,
        "section":sectionIndex,
        "sectionId":note.id,
        "title":note.title,  // cardName
        "passageAudio":[note.audio[0]?.url?note.audio[0].url:null],
        "passageAudioUnfiltered":[note.audio[0]?.url?note.audio[0].url:null], // nothing to filter
        "passageFileName":[note.audio[0]?.audio_filename?note.audio[0].audio_filename:null],
        "passageFileNameUnfiltered":[note.audio[0]?.audio_filename?note.audio[0].audio_filename:null], // nothing to filter
        "image":note.images[0]?.url?note.images[0].url:mapCategory(bibleId,note)?.images[0]?mapCategory(bibleId,note)?.images[0].url?mapCategory(bibleId,note)?.images[0].url:parentImage:parentImage,
        "audioTitle":note.title_audio?note.title_audio[0]?.url?note.title_audio[0].url:null:null,
        "passage":note.passage,
        "category":note.note_category,
        "categoryId":note.note_category_id,
        "color":mapCategory(bibleId,note)?.color,
        "duration":note.audio[0]?.duration?getTime(note.audio[0].duration):null,
        "show":showCard,
        "scriptureAddress": null,
        "imageOverlay":null
      });    
  }


export const getNotesList = (section, notesList)  => {
  return notesList.filter(note => note.section === section);
}

const getTotalDuration = (passages) => {
  let totalDuration = 0;
  passages.forEach(passage => {
    // console.log("getTotalDuration passage duration", passage.audio[0]?.duration);
    totalDuration += passage.audio[0]?.duration?passage.audio[0].duration:0;
  });
  // console.log("getTotalDuration", totalDuration);
  return getTime(totalDuration);
}