import React, { useEffect } from "react";
import { createPortal } from 'react-dom';
import { AudioNoteCategoryCard } from "../../components/AudioNoteCategoryCard";
import { getBibleId } from '../../utils/localSettings';
import { AppData } from "../../components/AppContext";
import { RxCross1 } from "react-icons/rx";
import "./style.css";

// For Shimmer loading effect
const placeholderCards = [  
  {
    category: "TMP1"
  },
  {
    category: "TMP2"
  },
  {
    category: "TMP3"
  },
  {
    category: "TMP4"
  }
];

const overlayHeading = "Note Categories to Autoplay";

export const CategoryModal = ( { 
  className, 
  toggleCategory,
  onClose } ) => {  
  
  const {categoryList} = AppData();
  const bibleId = getBibleId();
  const isCategoryPending = categoryList.current?false:true;

  /*------------------------------------------------------------------------------
    * useEffect on mount and unmount to set CSS styles
    *     Prevent the main page from scrolling while modal is open
    */
  useEffect(() => {
    const openModal = () => {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${scrollbarWidth}px`; // Add padding to compensate for scrollbar
    };
    
    const closeModal = () => {
      document.body.style.overflow = "auto"; // Restore overflow
      document.body.style.paddingRight = ""; // Remove padding
    };

    openModal();
    return () => {
      closeModal();
    };
  }, []);

  let cardIndex = 0;
  const defaultImage = "/img/comment-icon-256x256.png";
  
  // Prevent the main page from scrolling while modal is open
  // Style will be restored onClose
  // console.log("dom overlay:", document.body.style.overflow);
  // document.body.style.overflow = "hidden";

  return createPortal(    
    <>
      {/* <div className="category-overlay" onClick={onClose}/> */}
      <div className="category-overlay" />
      <div className={`category-modal ${className}`}>
        <div className="close-category-modal" >
        <button onClick={onClose}>
          <RxCross1 />
        </button>
        </div>
        <div className="audio-note">
          <div className="element-gradient-frame">
            <div className="header-frame">
              <div className="text-wrapper">{overlayHeading}</div>
            </div>
            <div className="card-frame">
              {isCategoryPending && placeholderCards.map(card => {  
                cardIndex++;        
                return (
                  <span className="skeleton-box" key={card.category}>
                    <AudioNoteCategoryCard
                      key={card.category}
                      cardIndex={cardIndex}
                      cardCategoryId={cardIndex}
                      cardAnimation = "shimmer"
                      className="audio-category-card-instance"
                      cardCategory={card.category}
                      cardAudioTitle={null}                  
                    />
                  </span>
                )
              })}
              {!isCategoryPending && categoryList.current && categoryList.current.map(card => {
                // console.log("category card:", card);
                return (
                  <AudioNoteCategoryCard
                    key={card.category}
                    cardIndex={cardIndex++}
                    className="audio-category-card-instance"
                    cardCategory={card.category}
                    cardCategoryId={card.id?card.id:null}
                    cardColor={card.color}
                    cardImage={card.images[0]?card.images[0].url?card.images[0].url:defaultImage:defaultImage}
                    cardAudioTitle={card.title_audio?card.title_audio[0]?.url?card.title_audio[0].url:null:null}
                    cardToggle={card.toggle}
                    toggleCategory={()=>toggleCategory(bibleId,card.id,categoryList.current)}
                  />
                )
              })}

              {!isCategoryPending && (!categoryList.current || categoryList.current.length === 0) &&
              <div> No categories defined </div>
              }
            </div>
          </div>
        </div>
      </div>
      
    </>,
    document.getElementById("modal-portal")
  ) ;
};

