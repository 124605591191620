
import React from "react";
import { ElementCard } from "../ElementCard";
import { DisplayMessage } from "../../components/DisplayMessage/DisplayMessage";
import { AppData } from "../../components/AppContext";
import { STORY_MODE, TRADITIONAL_MODE, BOOK_TYPE_BOOKS } from '../../constants.js';
import { GetAllBooks } from "../../utils/GetAllBooks";
import "./style.css";

const placeholderCards = [  
  {
    book_id: "TMP1",
    name: "_",
    name_alt: "_"
  },
  {
    book_id: "TMP2",
    name: "_",
    name_alt: "_"
  },
  {
    book_id: "TMP3",
    name: "_",
    name_alt: "_"
  },
  {
    book_id: "TMP4",
    name: "_",
    name_alt: "_"
  }
];

export const Books = ({ className }) => {
  const { storyMode, viewState, setCurrentBook, isOBTHelps } = AppData();  
  const { books, isPending, isError, error } = GetAllBooks(BOOK_TYPE_BOOKS, isOBTHelps);
  const isTraditionalMode = storyMode === TRADITIONAL_MODE;  
  
  // Temp for testing loading ...
  // let isPending = true, books = [], isError = false, error = null;
 
  let message = "";
  if (isError) {
    message = "Error fetching books.";         
    if (error && error.response && error.response.status && [403,404,500].includes(error.response.status)) {
      message = "Select a Language and Bible from the menu to view the books."
    }
    if (error && error.message) {
      if (error.message.includes("Network Error")) {
        message = "Unable to connect to the server. Please check your internet connection."
      } else {
        message = error.message;
      }
    }
  } 

  return (
    <div className={`books ${storyMode} ${viewState} ${className}`}>
      <div className="frame-books">        

        {isError && <DisplayMessage message={`${message}`}/>}

        {isPending && placeholderCards.map(book => {          
          return (
            <span className="skeleton-box" key={book.book_id}>
            <ElementCard
              key={book.book_id}
              cardCode={book.book_id}
              cardType={BOOK_TYPE_BOOKS}
              cardAnimation = "shimmer"
              cardName={ isTraditionalMode ? book.name : book.name_alt}
              cardDuration="_"
              cardImageClassName={`card-image-${viewState}`}
              //cardImage
              cardAudioTitle={null}
              cardState={viewState}
              subheadingClassName="element-card-subheading"                                 
              showPlay={false}     
              obtQuickPlayClassName={`card-quickplay-${viewState}`}
              showDownload={false}           
              obtDownloadClassName={`card-download-${viewState}`}
              overlapGroupClassName="instance-node"
              showAddToPlayList={false}
            />
            </span>
          )
        })}

        {!isPending && !isError && books && books.map(book => {
            return (
            <ElementCard
              key={book.book_id}
              cardCode={book.book_id}
              cardType={BOOK_TYPE_BOOKS}
              cardName={isTraditionalMode ? book.name : book.name_alt}
              cardNames={{[STORY_MODE]:book.name_alt, [TRADITIONAL_MODE]:book.name }}
              cardDuration={book.duration?book.duration:"-:--"}
              cardImageClassName={`card-image-${viewState}`}
              cardImage={book.images[0]?book.images[0].url?book.images[0].url:null:null}
              cardAudioTitle={isTraditionalMode ? ( book.title_audio?book.title_audio[0]?.url?book.title_audio[0].url:null:null ) 
                                                : ( book.title_audio_alt?book.title_audio_alt[0]?.url?book.title_audio_alt[0].url:null:null ) }
              cardState={viewState}
              subheadingClassName="element-card-subheading"                                 
              showPlay={false}    // TBD: Enable play from book
              obtQuickPlayClassName={`card-quickplay-${viewState}`}
              showDownload={false}  // TBD: Enable download book         
              obtDownloadClassName={`card-download-${viewState}`}
              overlapGroupClassName="instance-node"
              navigateTo="/movements"
              setCurrentCardAction={() => setCurrentBook}
              showAddToPlayList={true}
            />
            )
        })}

      </div>
    </div>
  );
};
