import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getModeParm }  from './getModeParm';
import { getBible } from './localSettings';

export const GetAllChapters = ( bookId, isOBTHelps, sectionsFlag=false ) => {
    const biblesPath = isOBTHelps?'helps' : 'bibles';
    let chapters = [];
    let {isMVP, modeParm} = getModeParm();
    let sectionsParm = `sections=${sectionsFlag}`;
    let thisParm = `?${sectionsParm}`;
    if (modeParm !== "") {
      thisParm = `${modeParm}&${sectionsParm}`
    }
    const { bibleId, isBibleBrain } = getBible();
    let url = '';
    const isEnabled = !!bibleId && !!bookId; // Only run when URL parms are truthy
    
    if (isBibleBrain) {
      url = `${process.env.REACT_APP_PROXY_URL}/bibles/${bibleId}/${bookId}/chapters${thisParm}`
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/${biblesPath}/${bibleId}/${bookId}/chapters${thisParm}`
    }

    // console.log("GetAllChapters", url);
    const { data, isPending, isError, error } = useQuery({
        queryKey: [bibleId,bookId,"chapters"],
        queryFn: () => axiosClient.get(url).then((res) => res.data),
        enabled: isEnabled,
        staleTime: 1000 * 60 * 30, // cache for 30 minutes
        refetchOnMount: false,
        refetchOnWindowFocus: false
    }
  );

  if (!isPending) {
    // console.log("GetAllChapters - data", data);
    // if (isAPM && data && data.data) {
    if (data && data.data) {
      if (isMVP) {
        if (data.data[0] && data.data[0].chapters) {
          chapters = data.data[0].chapters;
        }
      } else {
        // console.log("getAllChapters - data.data", data.data);
        if (data.data.chapters) {
          chapters = data.data.chapters;
          if (chapters.length > 1) {
            chapters.sort((a, b) => a.chapter - b.chapter);
          }
        }
      }    
    }
    // console.log("GetAllChapters - chapters", chapters);
  }
  // if (!isPending) console.log("GetAllChapters - chapters",chapters);
  let isPendingChapters = isPending;
  let isErrorChapters = isError;
  let errorChapters = error;  
  return { bookId, chapters, isPendingChapters, isErrorChapters, errorChapters }
}
