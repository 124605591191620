import React, {useEffect, useState, useRef} from "react";
// import { ObtDownload } from "../ObtDownload";
import { RxDownload, RxChevronDown, RxChevronUp } from "react-icons/rx";
import { ObtQuickPlay } from "../ObtQuickPlay";
import { PlayListAdd } from "../PlayListAdd";
import { ObtResourceChapters } from "../ObtResourceChapters";
import { ObtResourceExtras2 } from "../ObtResourceExtras2";
import { SpeakerAnimate } from "../SpeakerAnimate";
import { VIEWSTATE_SMALL,BOOK_TYPE_BOOKS } from '../../constants.js';
import { ObtExpandAll } from "../../components/ObtExpandAll";
import { toggleResourceOpen, getResourceOpen, setResourceLevel1Open } from '../../utils/resourceOpenFunctions.js';

import { AppData } from "../AppContext";
import "./style.css";

export const ObtResourceCard = ({
  cardCode=null,
  cardAnimation=null,  // used for placeholder shimmer on loading
  cardType,  
  cardIndex = null,  
  cardSection=null,
  cardName = "",
  cardDuration = "-:--",
  cardImageClassName,
  cardImage,
  cardAudioTitle = "",
  cardState,
  showPlay = false,
  obtQuickPlayClassName,
  showDownload = false,  
  obtDownloadClassName,
  iconAfter=false,
  setCurrentCardAction=null,
  downloadAction=null,
  showAddToPlayList=false,
  isExtras=false
}) => {
 
  const { storyMode, setCurrentBook, setCurrentBookName, setCurrentBookImage, setCurrentImage, setBookType, isOpenResource, setIsOpenResource,isOpenResourceLevel1,setIsOpenResourceLevel1  } = AppData();

  const [thisOpenResource, setThisOpenResource] = useState(false);  // tracks the individual resource and triggers refresh on toggle (e.g., expand all)
  
  // Use a placeholder image unless this is an animation card
  const thisImage = !(cardAnimation||cardImage)?process.env.REACT_APP_PLACEHOLDER_IMAGE:cardImage;
  const cardAnimationClass = cardAnimation?cardAnimation:"";

  const clickActions = () => {
    const setCurrentCard = setCurrentCardAction?setCurrentCardAction():null;
    if (setCurrentCard) {
      (cardCode !== null)?setCurrentCard(cardCode):setCurrentCard(cardIndex);      
      setCurrentImage(cardImage);
    }

    setBookType(BOOK_TYPE_BOOKS);
    setCurrentBookName(cardName);
    setCurrentBookImage(cardImage);
    toggleResourceOpen(cardCode, cardName, cardImage, setCurrentBook, setCurrentBookImage, setCurrentBookName, isOpenResource, setIsOpenResource);
    
  }

  const clickDownload = (event) => {
    console.log("TBD: Book Download clicked ");
    const thisDownload = downloadAction?downloadAction():null;
    if (thisDownload) {
      thisDownload(cardCode);
    }
    event.stopPropagation();
    
  }

  const quickPlayAction = (event) => {
    console.log("TBD:  quickPlayAction clicked ");
    event.stopPropagation();
  }

  const clickExpandCollapse = (event,expandFlag) => {
    // console.log("Card Expand Collapse clicked ", expandFlag);
    setResourceLevel1Open(cardCode, 'ALL', expandFlag, isOpenResourceLevel1, setIsOpenResourceLevel1);
    event.stopPropagation();
  }

  const isThisOpen = () => {
    return getResourceOpen(cardCode, isOpenResource, setIsOpenResource);
  }
  const fcnIsThisOpen = useRef();
  fcnIsThisOpen.current = isThisOpen;

  useEffect(() => {
    // Update context value here instead of during render
    setThisOpenResource(fcnIsThisOpen.current());
  }, [isOpenResource]);  

  return (
    <>
    <div className={`obtresource-card card-state-${cardState} open-${thisOpenResource}`} >
      <div className={`obtresource-card-header `} onClick={clickActions} >
        <div className={`image-wrapper ${cardAnimationClass}`}>
          {thisImage && <input type="image" 
                src={thisImage} alt="Card Image" 
                className={`card-image ${cardImageClassName}`} 
                name="btnCard" 
                id="btnCard"  
          />}       
          
        </div>
        <div className={`subheading `}>
            {!iconAfter && <SpeakerAnimate className={`title-icon-img ${cardAnimationClass}`} cardAudioTitle={cardAudioTitle} cardName={cardName}/>}

            <div className={`card-name ${cardAnimationClass}`}>{cardName}</div>

            {iconAfter && <SpeakerAnimate className={`title-icon-img ${cardAnimationClass}`} cardAudioTitle={cardAudioTitle} cardName={cardName}/>}

        </div>

        <div className="expand-wrapper">

          {showAddToPlayList && <PlayListAdd className={`book-playlist-add`}
                                          cardId={cardCode}
                                          cardType={cardType}
                                          cardMode={storyMode}
                                          cardBook={cardCode}
                                          cardParent={null}
                                          cardSection={null}
                                          cardName={cardName}
                                          cardPassageAudio={null}
                                          cardPassageFileName={null}
                                          cardPassage={null}
                                          cardCategory={null}
                                          cardCategoryId={null}
                                          cardDuration={null}
                                          cardImage={cardImage}
                                          cardAudioTitle={cardAudioTitle}
                                          cardColor={null}
                                          showCard={true}
                                          cardNotes={null}
                                          overrideDelete={false}
                                        />}
          {showDownload && <RxDownload className={`resource-download ${obtDownloadClassName}`} fill="rgb(235 241 249 / 50%)" onClick={(e) => clickDownload(e)}/>}
          {showPlay && <ObtQuickPlay className={`resource-quickplay ${obtQuickPlayClassName}`} stateProp="default" duration={cardDuration} playAction={quickPlayAction}/>}
          {thisOpenResource && <ObtExpandAll className="obt-expand-all-button" clickActions={clickExpandCollapse} />}
          {!thisOpenResource && 
            <RxChevronDown className="expand-resource" />
          } 
          {thisOpenResource && 
            <>
              <RxChevronUp className="expand-resource" />
            </>
          }
        </div>
      </div>

      {/* If the book is open show the chapters in a list */}
      {thisOpenResource && (
          <>
            {!isExtras && <ObtResourceChapters className="resource-chapters" viewState={VIEWSTATE_SMALL} bookId={cardCode} parentImage={cardImage} />}
            {isExtras && <ObtResourceExtras2 className="resource-movements" viewState={VIEWSTATE_SMALL} bookId={cardCode} parentImage={cardImage} />}
          </>
        )
      }

    </div>
    </>
    
        
  );
};
