import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getModeParm }  from './getModeParm';

export const GetBibles = ( iso, isOBTHelps, includeProxyAPI=false, queryEnabled=true ) => {
    const helpsPath = isOBTHelps?'/helps' : '';
    let includeBibleBrain = includeProxyAPI;
    const isEnabled = queryEnabled; // Only run when the query is enabled from caller
    let bibles = [];
    let {modeParm} = getModeParm();
    // console.log("iso", iso);
    // const language = iso;
    const divider = modeParm === "" ? "?" : "&";
    const languageParm=iso?`${divider}language_code=${iso}`:"";
    let thisURL = "";
    if (includeBibleBrain) {
       thisURL = `${process.env.REACT_APP_PROXY_URL}${helpsPath}/bibles${modeParm}${languageParm}`;
    } else {
       thisURL = `${process.env.REACT_APP_BASE_URL}${helpsPath}/bibles${modeParm}${languageParm}`;
    }
    
    // console.log("GetBibles: " + thisURL);
    const { data, isPending, isError, error, isFetching, isFetched } = useQuery({
        retry: false, // Disable retries
        queryKey: ['bibles',iso],
        queryFn: () => axiosClient.get(thisURL).then((res) => res.data),
        enabled: isEnabled,
        staleTime: 1000 * 60 * 30, // cache for 30 minutes
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });

    if (!isPending && data) {
      // console.log("GetBibles - data", data);
      bibles = data.data;
      
      if (!includeBibleBrain) {
        // When pulling directly from APM, we must set the source here.  
        // When pulling from proxy, the source is already set in the API response.
        bibles.forEach(bible => {
          bible.source = 'APM';
        });
      }

      if (bibles.length > 1) {
        bibles.sort((a, b) => (a.name?.toUpperCase() > b.name?.toUpperCase())?1:-1);
      }
      // console.log("GetBibles - bibles", bibles);
    }

  if (isFetching || !isFetched) {
    // console.log("GetBibles - isPending:" , isPending, "queryEnabled:" , isEnabled, "isFetching:" , isFetching, "isFetched:" , isFetched, "bibles:" , bibles);
  }
  if (isError) { 
    console.log("GetBibles - isError:" , isError, "error:" , error);
  }
  return { bibles, isPending, isError, error }
}
