
import React from "react";
import { useNavigate } from 'react-router-dom';
import { ObtButton } from "../../components/ObtButton";
import { AppData } from "../../components/AppContext";
import "./style.css";

export const ObtBibleButton = ( { className='' } ) => {
  const { currentBible } = AppData();
  const navigate = useNavigate();
  const bibleName = currentBible?currentBible['name']?currentBible['name']:"Select a Bible":"Select a Bible";

  return (
    <ObtButton
        className={`obt-button-bible-home ${className}`}
        hierarchy="link-gray"
        size="sm"
        stateProp="default"
        text={bibleName}
        bibleInfo={true}
        onClick={() => {navigate('/home');}} 
  />
  );
};

