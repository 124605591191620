import React from 'react';
import { ObtMenuItem } from "../ObtMenuItem";
import "./style.css";


export const Navigation = ({ className, breakpoint, setMobileOpen }) => {
  const showResources = process.env.REACT_APP_SHOW_RESOURCES_MENU==="true";
  const showExtras = process.env.REACT_APP_SHOW_EXTRAS_MENU==="true";
  const showExport = process.env.REACT_APP_SHOW_EXPORT_MENU==="true";
  return (  
    <>
      {breakpoint === "desktop" && 
        <div className={`navigation ${className}`}>
          <ObtMenuItem className="obt-menu-item-instance" name="home" stateProp="default" />
          <ObtMenuItem className="obt-menu-item-instance" name="languages" stateProp="default" />
          <ObtMenuItem className="obt-menu-item-instance" name="bibles" stateProp="default"/>     
          {showResources && <ObtMenuItem className="obt-menu-item-instance" name="resources" stateProp="default" />}
          {showExtras && <ObtMenuItem className="obt-menu-item-instance" name="extras" stateProp="default" />}
          {showExport && <ObtMenuItem className="obt-menu-item-instance" name="export" stateProp="default" />}
          {/* <ObtMenuItem className="obt-menu-item-instance" name="publishing" stateProp="default" /> */}
          <ObtMenuItem className="obt-menu-item-instance" name="support" stateProp="default" />
        </div>
      }

      {["mobile", "tablet"].includes(breakpoint) && 
        <div className={`navigation ${breakpoint} ${className}`}>
          <ObtMenuItem className="obt-menu-item-instance" name="home" stateProp="default" setMobileOpen={setMobileOpen}/>
          <ObtMenuItem className="obt-menu-item-instance" name="languages" stateProp="default"  setMobileOpen={setMobileOpen}/>
          <ObtMenuItem className="obt-menu-item-instance" name="bibles" stateProp="default" setMobileOpen={setMobileOpen}/>
          {showResources && <ObtMenuItem className="obt-menu-item-instance" name="resources" stateProp="default" setMobileOpen={setMobileOpen}/>}
          {showExtras && <ObtMenuItem className="obt-menu-item-instance" name="extras" stateProp="default" setMobileOpen={setMobileOpen}/>}
          {showExport && <ObtMenuItem className="obt-menu-item-instance" name="export" stateProp="default" setMobileOpen={setMobileOpen}/>}
          {/* <ObtMenuItem className="obt-menu-item-instance" name="publishing" stateProp="default"  setMobileOpen={setMobileOpen}/> */}
          <ObtMenuItem className="obt-menu-item-instance" name="support" stateProp="default"  setMobileOpen={setMobileOpen}/>
        </div>
      }
      
    </>  
  );
};
