import React, { useEffect } from "react";
import useWindowWidth from "../../hooks/useWindowWidth";
import { trackPage } from "../../utils/analytics.js";
import { ObtHeader } from "../../components/ObtHeader";
import { ObtFooter } from "../../components/ObtFooter";
import { ObtViewButtons } from "../../components/ObtViewButtons";
import { CopyrightOverlay } from "../../components/CopyrightOverlay";
// import { FabPlay } from "../../components/FabPlay";
import { StoryMode } from "../../components/StoryMode";
import { Movements } from "../../components/Movements";
import { Chapters } from "../../components/Chapters";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { BookSlider } from "../../components/BookSlider";
import { AudioPlaylist } from "../../components/AudioPlaylist";
import { AppData } from "../../components/AppContext";
import { STORY_MODE, TRADITIONAL_MODE, BOOK_TYPE_EXTRAS } from '../../constants.js';
import "./style.css";

export const MovementsPage = () => {
  const { dataBreakpointsMode } = useWindowWidth();
  const { storyMode, currentBookName, currentBookNames, bookType, showPlayList,isOpenPlayer,currentBook, isStorying } = AppData();
  const pageClass = (storyMode === STORY_MODE)?"movements":"chapters";
  const isExtras = (bookType === BOOK_TYPE_EXTRAS);
  const movementHeading = isExtras ? "RESOURCES" : (isStorying ? "STORY SETS" : "MOVEMENTS");
  const chapterHeading = isStorying ? "STORIES" : "CHAPTERS";
  const isTraditionalMode = !isExtras && storyMode === TRADITIONAL_MODE;
  const thisBookName = currentBookNames?currentBookNames[storyMode]:currentBookName;
  const sourceId = currentBook;
  const classPlayList = showPlayList ? "with-playlist" : "";
  
  useEffect(() => {
    // Trigger page metrics when mode changes
    const thisPage = (storyMode === STORY_MODE)?"movements":"chapters";    
    trackPage(thisPage, storyMode, currentBook);
  }, [storyMode, currentBook]);

  return (   
  <>
    
    <div className={`movements-page background-wallpaper ${dataBreakpointsMode} ${classPlayList}`} data-breakpoints-mode={dataBreakpointsMode}>
      <div className="left-wrapper">

        <div className="hero-header-section">
            <ObtHeader
              breakpoint={dataBreakpointsMode}
              className="obt-header-navigation-menu"
            />
        </div>
        
        <div className={`frame-tabs-container ${dataBreakpointsMode}`}>
          <div className={`frame-tabs frame-tabs-${dataBreakpointsMode}`}> 

            <Breadcrumbs page={pageClass} className='frame-tabs-left'/>

            {dataBreakpointsMode === "desktop" && 
              <div className="frame-title-container-same-line frame-tabs-center">
                <div className={`heading-4 ${dataBreakpointsMode}`}>{thisBookName}</div>
              </div>
            }
            
            <div className="horizontal-tabs">          
              <StoryMode className="story-mode-toggle" />
              <div className="button">
                <ObtViewButtons className="obt-view-buttons" />
              </div>
            </div>
          </div>
        </div>
        
        {dataBreakpointsMode !== "desktop" && 
          <div className="frame-title-container-below">
            <div className={`heading-4 ${dataBreakpointsMode}`}>{thisBookName}</div> 
          </div>
        }
        

        <div className="main-content-area">
            <div className="left-frame" >
             
              <BookSlider/>
              
              {(isExtras || storyMode === STORY_MODE) && (
                <div className="movements-container" >
                  <div className={`heading-4 ${dataBreakpointsMode}`}>{movementHeading}</div>
                  <Movements className={pageClass} />                
                </div>
              )}

              {(isTraditionalMode) && (
                
                <div className="movements-container" >
                  <div className={`heading-4 ${dataBreakpointsMode}`}>{chapterHeading}</div>
                  <Chapters className={pageClass} />
               </div>
              )}
            </div>         
        </div>

        <CopyrightOverlay />
        
        <div className="obt-footer">        
          <div className="container-footer">
              <ObtFooter  breakpoint={dataBreakpointsMode} />
          </div>
        </div>
      </div>

      <div className="right-wrapper" >
        {dataBreakpointsMode === "desktop" && showPlayList &&
          <>            
            
            {!isOpenPlayer && 
              <div className="right-frame">
                <AudioPlaylist 
                  bookId={sourceId}
                  dataBreakpointsMode={dataBreakpointsMode}
                  />   
              </div>
            }
          </>
        }

        {dataBreakpointsMode !== "desktop" && showPlayList &&
          <AudioPlaylist 
          bookId={sourceId}
          dataBreakpointsMode={dataBreakpointsMode}          
          />   
        }

        
      </div>
  </div>
  
  <div>
      <br/>
      <br/>
      <br/>
    </div>
  
  </>
    
  );
};
