
import PropTypes from "prop-types";
import React, {useState} from "react";
import { useSwiper  } from 'swiper/react';
import { useNavigate } from "react-router-dom";
import { ObtDownload } from "../ObtDownload";
import { ObtQuickPlay } from "../ObtQuickPlay";
import { SpeakerAnimate } from "../SpeakerAnimate";
import { PopupMessage } from "../PopupMessage";
import { PlayListData } from "../../components/AudioPlayListContext";
import { addToPlayList } from "../../utils/playlistFunctions.js";
import { trackCardStart } from "../../utils/analytics.js";
import { PlayListAdd } from "../PlayListAdd";
import { setAudioPassage } from "../../utils/setAudioPassage.js";
import { BOOK_TYPE_BOOKS, BOOK_TYPE_EXTRAS, BOOK_TYPE_RESOURCES } from '../../constants.js';
import { AppData } from "../../components/AppContext";
import "./style.css";

export const ElementCard = ({
  cardKey,
  cardCode=null,
  cardId=null,
  cardAnimation=null,  // used for placeholder shimmer on loading
  cardType,  
  cardIndex=null,
  cardParent=null,  // name of the movement or chapter
  cardSection=null,
  cardSectionId=null,
  cardName = "",
  cardNames = null,
  cardDuration = "00:00",
  cardChapter,
  cardMovement,
  cardImageClassName,
  cardImage,
  cardAudioTitle = "",
  cardState,
  cardColor=null,
  cardCategory=null,
  cardCategoryId=null,
  cardPassageAudio=[],
  cardPassage=null,
  cardPassageFileName=null,
  subheadingClassName,
  showPlay = true,
  obtQuickPlayClassName,
  showDownload = true,  
  obtDownloadClassName, 
  overlapGroupClassName,
  isSlider = false,  
  iconAfter=false,
  navigateTo = null,
  setCurrentCardAction=null,
  playAudioOnClick=false,
  downloadAction=null,
  cardNotes=null,
  showAddToPlayList=false,
  scriptureAddress=null,
  imageOverlay=null
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);
  const navigate = useNavigate();
  
  const { storyMode, currentBook, setCurrentBookImage, setCurrentBookName, setCurrentBookNames, setCurrentResourceName, setNavigateFrom, setCurrentImage, 
          setCurrentChapter, setCurrentMovement, setCurrentTrack, trackIndex, setTrackIndex, setCurrentSection,  
          setBookType, setAudioLoading, setIsOpenControls, enableControls } = AppData();
  const swiper = useSwiper();

  // Use a placeholder image unless this is an animation card
  const thisImage = !(cardAnimation||cardImage)?process.env.REACT_APP_PLACEHOLDER_IMAGE:cardImage;
  const cardAnimationClass = cardAnimation?cardAnimation:"";
  const withOverlay = (imageOverlay)?"with-overlay":"";
  const showAddress = (scriptureAddress)?"scripture-address":null;

  const clickActions = () => {
    // console.log("Element card click - cardType: " + cardType);
    const setCurrentCard = setCurrentCardAction?setCurrentCardAction():null;
    if (setCurrentCard) {
      // console.log('ElementCard - setCurrentCard: cardCode: ', cardCode, "cardIndex", cardIndex);
      (cardCode !== null)?setCurrentCard(cardCode):setCurrentCard(cardIndex);      
      if (cardType !== "section") {
        // Save the selected image to use as a default for any future children
        setCurrentImage(cardImage);
      }
    }
    if (cardType === BOOK_TYPE_BOOKS) {
      setBookType(BOOK_TYPE_BOOKS);
      setCurrentBookName(cardName);
      setCurrentBookNames(cardNames);  // both story and traditional
      setCurrentBookImage(cardImage);
      if (isSlider) {
        swiper.slideTo(cardIndex, 0)
      }
    } else if (cardType === BOOK_TYPE_EXTRAS) {
      setBookType(BOOK_TYPE_EXTRAS);
      if (cardSection === null ) {
        // Only change if main card not secion card
        setCurrentBookName(cardName);
        setCurrentBookNames(cardNames);  // both story and traditional
        setCurrentBookImage(cardImage);
        // For now we assume only one movement per extra
        // Extras skip directly to sections page from the main page
        setCurrentMovement(1); 
      }
    } else if (cardType === BOOK_TYPE_RESOURCES && cardSection === null ) {
      // Only change if main card not secion card
      setCurrentResourceName(cardName);
      // For now we assume only one movement per resource
      // Resources skip directly to sectinos page from the main page
      setCurrentMovement(1); 
    }

    // Keep movement and card in sync for toggling story mode
    if (cardType === "movement") {
      setCurrentChapter(cardChapter);
      // console.log("ElementCard setCurrentChapter: " + cardChapter);
    } else if (cardType === "chapter") {
      setCurrentMovement(cardMovement);
      // console.log("ElementCard setCurrentMovement: " + cardMovement);
    }

    setNavigateFrom(cardType);    

    if (playAudioOnClick) {
      if (cardPassageAudio) {
        playActions();
      }      
    } 

    if (navigateTo) {
      navigate(navigateTo);
    }
  }

  const { dispatch, captureTrackEnd, startTime } = PlayListData();

  /**
   * 
   * @param {*} event 
   * 
   * Adds the current card to the playlist, then sets the audio passage to begin playing
   */
  const playActions = () => {
    if (cardPassageAudio !== null && cardPassageAudio.length > 0) {
      let {playIndex} = addToPlayList(
                    {
                      cardId:cardId,
                      cardType:cardType,
                      cardMode:storyMode,
                      cardBook:currentBook,
                      cardParent:cardParent,
                      cardSection:cardSection,
                      cardName:cardName,
                      cardPassageAudio:cardPassageAudio,
                      cardPassageFileName:cardPassageFileName,
                      cardPassage:cardPassage,
                      cardCategory:cardCategory,
                      cardCategoryId:cardCategoryId,
                      cardDuration:cardDuration,
                      cardImage:cardImage,
                      cardAudioTitle:cardAudioTitle,
                      cardColor:cardColor,
                      showCard:true,
                      overrideDelete:false,  // do not override if card is just added due to playing
                      cardNotes:cardNotes,
                      dispatch:dispatch
                  });
      // console.log("ElementCard - captureTrackEnd startTime: ", startTime);
      captureTrackEnd("track-change", trackIndex, startTime);
      trackCardStart("click", currentBook, storyMode, cardType, null, cardParent, cardSection, cardName);
      setAudioPassage(cardPassageAudio,playIndex,cardSection,setCurrentTrack,setTrackIndex,setCurrentSection,setAudioLoading);
      enableControls.current = true;
      setIsOpenControls(true);
    } else {
      const message = "No audio passage available for '" + cardName + "'";
      console.log(message);
      setDisplayMessage(message);
      setIsVisible(true);
    }
  }
      

  const clickDownload = () => {
    //console.log("Download clicked for section ", cardSection)
    const thisDownload = downloadAction?downloadAction():null;
    if (thisDownload) {
      thisDownload(cardSection);
    }
    
  }
 
  let cardStyle = {};
  if (cardColor) {
    // Append opacity to the color - must use 2-digit hex "alpha" value to append to the #RRGGBB value: 75% = 0xBF; 80%=0xCC
    // The alpha value AA is a hex representation of 0-100 where 0 = 00 and 100 = FF.   round(AA / 100) * 255 - then convert to hex
    let color = cardColor + 'BF' 
    cardStyle = { borderColor: color }
  }

  return (
    <div className={`element-card card-state-${cardState}`} >      
        <div className={`div ${overlapGroupClassName} ${cardAnimationClass} ${withOverlay}`} style={cardStyle} >
          {imageOverlay && <div className={`image-overlay ${cardAnimationClass}`}>{imageOverlay}</div>}
          {thisImage && <input type="image" 
                onClick={clickActions} 
                src={thisImage} alt="Card Image" 
                className={`card-image ${cardImageClassName}`} 
                name="btnCard" 
                id="btnCard"  
          />}
          {showAddToPlayList && 
                <PlayListAdd className={`section-playlist-add`}
                      cardId={cardId}
                      cardType={cardType}
                      cardMode={storyMode}
                      cardBook={ (cardType === BOOK_TYPE_BOOKS || cardType === BOOK_TYPE_EXTRAS)?cardCode:currentBook}
                      cardParent={cardParent}
                      cardSection={cardSection}
                      cardName={cardName}
                      cardPassageAudio={cardPassageAudio}
                      cardPassageFileName={cardPassageFileName}
                      cardPassage={cardPassage}
                      cardCategory={cardCategory}
                      cardCategoryId={cardCategoryId}
                      cardDuration={cardDuration}
                      cardImage={cardImage}
                      cardAudioTitle={cardAudioTitle}
                      cardColor={cardColor}
                      showCard={true}
                      cardNotes={cardNotes}
                      overrideDelete={false}
                />}
          {showDownload && <ObtDownload className={obtDownloadClassName} onClick={clickDownload}/>}
          {showPlay && <ObtQuickPlay className={obtQuickPlayClassName} stateProp="default" duration={cardDuration} />}
          {showAddress && <div className={`${showAddress}`}>{scriptureAddress}</div>}
        </div>
        <PopupMessage
          message={displayMessage}
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
        />
        <div className={`subheading ${subheadingClassName}`}>
            {!iconAfter && <SpeakerAnimate className={`title-icon-img ${cardAnimationClass}`} cardAudioTitle={cardAudioTitle} cardName={cardName}/>}

            <div className={`card-name ${cardAnimationClass}`}>{cardName}</div>

            {iconAfter && <SpeakerAnimate className={`title-icon-img ${cardAnimationClass}`} cardAudioTitle={cardAudioTitle} cardName={cardName}/>}

        </div>
        
    </div>
  );
};

ElementCard.propTypes = {
  cardType: PropTypes.oneOf([BOOK_TYPE_BOOKS, "movement", "chapter", "section", BOOK_TYPE_RESOURCES, BOOK_TYPE_EXTRAS]),
  cardCode: PropTypes.string,
  cardName: PropTypes.string,
  cardDuration: PropTypes.string,
  cardImage: PropTypes.string,
  cardIndex: PropTypes.number,
  cardChapter: PropTypes.number,
  cardMovement: PropTypes.number,
  showPlay: PropTypes.bool,  
  showDownload: PropTypes.bool,      
  cardState: PropTypes.oneOf(["large", "small", "carousel"]),  
  cardAudioTitle: PropTypes.string,
  navigateTo: PropTypes.string,
  playAudioOnClick: PropTypes.bool
};

