
export const isAndroid = async () => {  
    if (navigator.userAgentData) {
      // console.log("navigator.userAgentData", navigator.userAgentData)
      return /Android/i.test(navigator.userAgent);
    } else {
      // console.log("navigator.userAgent", navigator.userAgent)
      // Fallback for browsers that do not support navigator.userAgentData
      const userAgent = navigator.userAgent.toLowerCase();
      return /Android/i.test(userAgent);
    }
}
