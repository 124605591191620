import React, { useRef, useEffect }  from "react";
import { createPortal } from 'react-dom';
import { RxCross1 } from "react-icons/rx";
import { PlayListData } from "../AudioPlayListContext";
import { addToPlayList, getPlayListNextIndex } from "../../utils/playlistFunctions.js";
import { AppData } from "../../components/AppContext";
import { GetAllMovements } from "../../utils/GetAllMovements";
import { GetAllSections } from "../../utils/GetAllSections";
import { pushSection,  pushMovementNote} from "../../utils/listFunctions.js";
import { getBibleId } from '../../utils/localSettings';
import { STORY_MODE, BOOK_TYPE_EXTRAS, BOOK_TYPE_BOOKS } from '../../constants.js';
import "./style.css";

export const PlayListAddModal =  ({ 
    className, 
    cardId=null,
    cardType=null,
    cardMode=null,
    cardBook=null,
    cardParent=null,
    cardSection=null,
    cardName=null,
    open,
    isOpenPlayListModal,
    playListAddComplete,
    setPlayListAddComplete,
    playListAddError,
    setPlayListAddError,
    onClose } ) => {

  // console.log("PlayListAddModal : cardId: ", cardId, "cardType: ", cardType, "cardMode: ", cardMode, "cardBook: ", cardBook, "cardParent: ", cardParent, "cardSection: ", cardSection, "cardName: ", cardName, "open: ", open);
  
  const { dispatch } = PlayListData();
  const { storyMode, currentResource, currentImage, navigateFrom, isOBTHelps, bookType} = AppData();
  const bibleId = getBibleId();
  
  const parentCode = cardId;
  const thisBookType = bookType?bookType:(cardType === BOOK_TYPE_EXTRAS?BOOK_TYPE_EXTRAS:BOOK_TYPE_BOOKS);
  const parent = (bookType === BOOK_TYPE_EXTRAS) || (storyMode === STORY_MODE)?"movements":"chapters";

  // Only one of the queries will be executed based on the cardType - pass in the flag 
  const sectionsQuery = useRef(!playListAddComplete && (cardType === "chapter" || cardType === "movement") ? true : false);
  const bookQuery = useRef(!playListAddComplete && (cardType === BOOK_TYPE_BOOKS || cardType === BOOK_TYPE_EXTRAS) ? true : false); 

  let { sourceId: bookId, sections, notes, isPending: isSectionsPending, 
        isError: isSectionsError, error: sectionsError } = GetAllSections(cardBook, parent, parentCode, currentResource, navigateFrom, thisBookType, isOBTHelps, sectionsQuery.current);
  let { movements, isPendingMovements, isErrorMovements, errorMovements } = GetAllMovements(cardBook, cardType, isOBTHelps, "PlayListAdd", true, bookQuery.current);

  // Temp for testing - comment out the queries above and uncomment the following line to simulate errors, etc.
  // let isSectionsPending = false, sections = [], notes = [], bookId = null, isSectionsError = true, sectionsError = "Testing forced sections error";
  // let isBooksPending = false, books = [], isBooksError = false, booksError = "Testing forced books error";

  /*------------------------------------------------------------------------------
   * useEffect on mount and unmount to set CSS styles
   *     Prevent the main page from scrolling while modal is open
   *     Adjust CSS to pad the scrollbar width when overflow is hidden
   */
  useEffect(() => {
    const openModal = () => {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${scrollbarWidth}px`; // Add padding to compensate for scrollbar
    };
    
    const closeModal = () => {
      document.body.style.overflow = "auto"; // Restore overflow
      document.body.style.paddingRight = ""; // Remove padding
    };

    openModal();
    // console.log("----> MOUNT PlayListAddModal");

    return () => {
      // console.log("====> UNMOUNT PlayListAddModal");
      closeModal();
    };
  }, []);

  
  /**
   * loadNotes
   */
  const loadNotes = async (notes) => {
    if (notes && notes.length > 0) {
      // console.log("Loading notes", notes);
      notes.forEach(card => {
        cardTrackIndex.current++;
        pushMovementNote(card,passageListRef.current,cardTrackIndex.current,bibleId,bookId,parent,parentCode?parentCode:0,currentImage?currentImage:null);
        // console.log("Movement notes:", passageListRef.current);
      });
    }
  }
  
  /**
   * loadPassages
   */
  const loadPassages = async (sections) => {
    if (sections && sections.length > 0) {
      // console.log("Loading sections", sections);
      sections.forEach(card => {
        cardTrackIndex.current++;
        pushSection(card,passageListRef.current,notesListRef.current,cardTrackIndex.current,bibleId,bookId,parent,parentCode?parentCode:0,currentImage?currentImage:null,isOBTHelps);
        // console.log("Section passages:", passageListRef.current);
      });
    }
  }   

  
  const passageListRef = useRef([]);
  const notesListRef = useRef([]);
  const cardTrackIndex = useRef(0);
  const fcnLoadNotes = useRef(loadNotes);
  const fcnLoadPassages = useRef(loadPassages);
  // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  /**
     * loadPlaylist() - local function to add all sections to the playlist
     */
  const loadPlaylist = async () => {
    let passageList = passageListRef.current;
    let notesList = notesListRef.current;
    // console.log("loadPlaylist .... passageList:", passageList);

    if (passageList && passageList.length > 0) {
      // Keep track of the last index in the playlist so we can insert additional sections in the correct order
      let lastIndex = getPlayListNextIndex();
      // console.log("lastIndex = ", lastIndex);
      for (const card of passageList) {
        // Get all the notes for this section
        let sectionNotes = [];
        if (notesList && notesList.length > 0) {
          sectionNotes = notesList.filter(note => note.section === card.section && note.parent === card.parent )
        }

        // await sleep(5000);  // slow down for testing purposes

        // Add the section to the playlist
        // console.log("addToPlayList - section", card, "lastIndex", lastIndex, "sectionNotes:", sectionNotes);
        // Dev note: VS Code is incorrectly flagging the await as not having any effect here, but removing it results in incorrect behavior.
        let {nextIndex} = await addToPlayList(
          {
            cardIndex: lastIndex,
            cardId: card.id,
            cardType: "section",
            cardMode: cardMode,
            cardBook: cardBook,
            cardParent: card.parent,
            cardSection: card.section,
            cardName: card.title,
            cardPassageAudio: card.passageAudio,
            cardPassageFileName: card.passageFileName,
            cardPassage: card.passage,
            cardCategory: card.category,
            cardCategoryId: card.categoryId,
            cardDuration: card.duration,
            cardImage: card.image,
            cardAudioTitle: card.audioTitle,
            cardColor: card.color,
            showCard: true,
            overrideDelete: false,
            cardNotes: sectionNotes,
            dispatch: dispatch
          }
        );
        // const tempPlaylist = getPlaylist();
        // console.log("addToPlayList - playlist", tempPlaylist);
        // console.log("nextIndex = ", nextIndex);
        lastIndex = nextIndex+1;
      }
      // console.log("Setting setPlayListAddComplete to TRUE");
      setPlayListAddComplete(true);

    } else {
      setPlayListAddError(true);
    }
  }

  const fcnloadPlaylist = useRef(loadPlaylist);

  /*------------------------------------------------------------------------------
   * MOVEMENTS - load to playlist
  */
  useEffect(() => {
    
    // console.log("useEffect - ",
    //   "playListAddComplete", playListAddComplete,
    //   "bookQuery", bookQuery.current,
    //   "isPendingMovements", isPendingMovements, 
    //   "isErrorMovements", isErrorMovements, 
    //   "movements", movements, 
    //   );

    /**
     * loadData() - local function to preload notes and passages 
     */
    const loadData = async () => {
      // console.log("loadData movements ........");
      notesListRef.current = [];
      passageListRef.current = [];
      
      // Load notes and passages for each movement
      for (const movement of movements) {
        await fcnLoadNotes.current(movement.audio_notes);
        await fcnLoadPassages.current(movement.sections);
      }
      
      // console.log("Notes and passages loaded", passageListRef.current);
      await fcnloadPlaylist.current();
    }

    /**
     * Main useEffect Logic
     */
    if  (bookQuery.current && !playListAddComplete && isOpenPlayListModal 
        && !isPendingMovements && !isErrorMovements && movements ) {
      //)) {
      loadData();
    }
  }, [playListAddComplete, isOpenPlayListModal, isPendingMovements, isErrorMovements, movements]);

  /*------------------------------------------------------------------------------
   * SECTIONS AND NOTES - load to playlist
   */
  useEffect(() => {
    
    // console.log("useEffect - ",
    //   "playListAddComplete", playListAddComplete,    
    //   "isSectionsPending", isSectionsPending, 
    //   "isSectionsError", isSectionsError,
    //   "sections:", sections, 
    //   "notes:", notes
    //   );    

    /**
     * loadData() - local function to preload notes and passages
     */
    const loadData = async () => {
      // console.log("loadData sections........");
      notesListRef.current = [];
      passageListRef.current = [];
      await fcnLoadNotes.current(notes);
      await fcnLoadPassages.current(sections);
      // console.log("Notes and passages loaded", passageListRef.current);
      await fcnloadPlaylist.current();
    }

    /**
     * Main useEffect Logic
     */    
    if ( sectionsQuery.current && !playListAddComplete && isOpenPlayListModal 
        && !isSectionsPending && !isSectionsError && ((sections && sections.length > 0) || (notes && notes.length > 0))) 
    { 
      loadData();
    }
  }, [playListAddComplete, isOpenPlayListModal, isSectionsPending, isSectionsError, sections,notes]);


  /*------------------------------------------------------------------------------
   * useEffect - Close the modal after the playlist is loaded.
   */  
  useEffect(() => {
    // console.log("useEffect - playListAddComplete", playListAddComplete);
    if (playListAddComplete) {
        onClose();
    }
  }, [playListAddComplete, onClose]);


  /*------------------------------------------------------------------------------
    RENDER MODAL
  */
  if (!open) return null;

  return createPortal(
    <>
      <div className="playlist-add-overlay" onClick={(e) => e.stopPropagation() /* Prevent clicks on parent card*/}/>
      <div className={`playlist-add-modal ${className}`} onClick={(e) => e.stopPropagation() }>
        <div className="close-playlist-add-modal" >
        { (isSectionsError || playListAddError || isErrorMovements) && <button onClick={onClose}>
          <RxCross1 />
        </button>
        }
        </div>
        <div className="playlist-add-body">
          <div className="playlist-add-gradient-frame">
            <div className="header-frame">
              
              {/*  CHAPTER or MOVEMENT */}

                {sectionsQuery.current && (isSectionsError || playListAddError) &&
                  <>
                  <div className="text-wrapper">Unable to add {cardType} to playlist</div>
                  <div className="text-wrapper">{sectionsError}</div>
                  </>
                }

                {sectionsQuery.current && !playListAddComplete && !isSectionsError && !playListAddError &&
                  <>
                  <div className="spinner"></div>
                  <div className="text-wrapper">Adding {cardType} to playlist...</div>
                  </>
                }

              {/*  BOOK  */}
              
                {bookQuery.current && (isErrorMovements || playListAddError) &&
                  <>
                  <div className="text-wrapper">Unable to add {cardType} to playlist</div>
                  <div className="text-wrapper">{errorMovements}</div>
                  </>
                }
                
                {bookQuery.current && !playListAddComplete && !isErrorMovements && !playListAddError &&
                  <>
                  <div className="spinner"></div>
                  <div className="text-wrapper">Adding {cardType} to playlist...</div>
                  </>
                }

            </div>
          </div>
        </div>
      </div>
      
    </>,
    document.getElementById("modal-portal")
  ) ;
}
