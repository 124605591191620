import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getCategoryToggle, setLocalCategories } from "./categoryFunctions.js";
import { getModeParm }  from './getModeParm';
import { getBible } from './localSettings';

export const GetNoteCategories = (queryEnabled=true) => {
    let { bibleId, isAPM } = getBible();
    const isEnabled = !!bibleId && queryEnabled // Only run when `bibleId` is truthy and the query is enabled from caller
    let url = "";
    if (isAPM) {
      let {modeParm} = getModeParm();    
      url = `${process.env.REACT_APP_BASE_URL}/bibles/${bibleId}/note_categories${modeParm}`
    } else {
      url = `${process.env.REACT_APP_PROXY_URL}/bibles/${bibleId}/note_categories`
    } 
    
    let categories = [];
    // console.log("GetNoteCategories - url: " , url)

    const { data, isPending, isError, error, isFetching } = useQuery({
        queryKey: [bibleId,'note-categories'],
        queryFn: () => axiosClient.get(url).then((res) => res.data),
        enabled: isEnabled,
        staleTime: 1000 * 60 * 5, // cache for 5 minutes
    });  
    
    let isCategoryPending = isPending;

    if (!isPending && data) {
      // Clone the data 
      categories = JSON.parse(JSON.stringify(data.data));

      for (const category of categories) {
        // Add an extra element to track which categories are enabled
        // Use the last saved value from local storage if it exists
        category["toggle"] = getCategoryToggle(bibleId,category.id);
        //console.log("category toggle: ", category.id, category["toggle"] );
      }

      // Save the updated categories in the browser's local storage
      //console.log("GetNoteCategories: setLocalCategories ", bibleId, categories);
      setLocalCategories(bibleId, categories);
    }

  if (isFetching) {
    // console.log("GetNoteCategories - isPending:" , isPending, "queryEnabled:" , isEnabled, "isFetching:" , isFetching, "isFetched:" , isFetched, "categories:" , categories);
  }
  return { categories, isCategoryPending, isError, error }
}