import {createContext, useContext, useState, useRef, useEffect } from 'react';
import { localSettings, getLanguage, setLanguage, getLocalBible, setBible } from "../../utils/localSettings.js";
import { useSessionStorage } from "../../hooks/useSessionStorage.js";
import { getLocalCategories } from "../../utils/categoryFunctions.js";
import { isApple } from "../../utils/isApple.js";
import { isAndroid } from "../../utils/isAndroid.js";
import { STORY_MODE, TRADITIONAL_MODE, VIEWSTATE_SMALL, VIEWSTATE_LARGE, NOTE_MODE_OPENED, NOTE_MODE_CLOSED } from '../../constants.js';
import { AnalyticsBrowser } from '@segment/analytics-next'

const AppContext = createContext();

export const AppData = () => useContext(AppContext);

// We can export this instance to share with rest of our codebase.
export const segmentAnalytics = AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_KEY });

export const AppWrapper = ({children}) => {
    /* Define states to be shared across components and screens */   

    // To test for apple devices - must be done asynchronously
    const [isAppleDevice, setIsAppleDevice] = useState(false);
    const [isAndroidDevice, setIsAndroidDevice] = useState(false);

    const [currentLanguage, setCurrentLanguage] = useState(getLanguage());
    const [currentBible, setCurrentBible] = useState(getLocalBible());

    const [isStorying, setIsStorying] = useState(false);

    const categoryList = useRef();

    const [isOBTHelps] = useState(process.env.REACT_APP_NAME === "OBT Helps");

    const [storyMode, setStoryMode] = useState();
    const toggleMode = () => {
        let newMode = storyMode===STORY_MODE?TRADITIONAL_MODE:STORY_MODE;
        setStoryMode(newMode);
        localSettings('setItem', 'storyMode', {mode:newMode});
    }

    const [viewState, setViewState] = useState();    
    const toggleViewState = () => {
        let newViewState = viewState===VIEWSTATE_LARGE?VIEWSTATE_SMALL:VIEWSTATE_LARGE;
        setViewState(newViewState);
        localSettings('setItem', 'viewState', {state:newViewState});
    }

    const [includeProxyAPI, setIncludeProxyAPI] = useState(process.env.REACT_APP_BIBLE_BRAIN_INCLUDE === 'true');

    //-----------------------------------------------------------------------------
    // Initialize states and fetch data when the component mounts
    //-----------------------------------------------------------------------------
    useEffect(() => {
        const checkPlatform = async () => {
        const result = await isApple();
        console.log("isAppleDevice: ", result);
        setIsAppleDevice(result);
        const result2 = await isAndroid();
        console.log("isAndroid: ", result2);
        setIsAndroidDevice(result2);
        };
        
        // Check device platform and set flags
        checkPlatform();

        // currentLanguage
        // Start with the saved language code from local storage
        let thisLanguage = getLanguage();
        // console.log("localsettings thisLanguage", thisLanguage);
        if (thisLanguage === undefined || thisLanguage === null || thisLanguage.iso === undefined ) {
            let defaultLanguage = {iso:process.env.REACT_APP_LANGUAGE_ISO, name:process.env.REACT_APP_LANGUAGE_NAME};
            console.log("Using default Language", defaultLanguage);
            thisLanguage = {iso:defaultLanguage.iso, name:defaultLanguage.name};
            setLanguage(thisLanguage);
            setCurrentLanguage(thisLanguage);
        }
        
        // currentBible
        // Start with the saved bible values from local storage        
        let thisBible = getLocalBible();
        console.log("localsettings thisBible", thisBible);
        if (!thisBible?.id) {
            // Set default Bible if none found
            let defaultLanguage = {iso:process.env.REACT_APP_LANGUAGE_ISO, name:process.env.REACT_APP_LANGUAGE_NAME};
            if (thisLanguage.iso === defaultLanguage.iso) {
                let defaultBible = {id:process.env.REACT_APP_BIBLE_ID, name:process.env.REACT_APP_BIBLE_NAME, source:"APM"};
                console.log("Using default Bible", defaultBible);
                thisBible = {id:defaultBible.id, name:defaultBible.name, source:defaultBible.source};
                setBible(thisBible);
                setCurrentBible(thisBible);
            }
        }

        // storyMode
        let thisMode = localSettings('getItem', 'storyMode');
        let isOBTHelps = process.env.REACT_APP_NAME === "OBT Helps";
        if (isOBTHelps) {
            thisMode = {mode:TRADITIONAL_MODE};
        } else {
            if (!thisMode || !thisMode.mode ) {
                thisMode = {mode:STORY_MODE};
            }
        }
        localSettings('setItem', 'storyMode', thisMode);
        setStoryMode(thisMode.mode);

        // viewState
        let thisViewState = localSettings('getItem', 'viewState');
        if (thisViewState === undefined || thisViewState === null || thisViewState.state === undefined ) {
            thisViewState = {state:VIEWSTATE_LARGE};
            localSettings('setItem', 'viewState', thisViewState);
        }
        setViewState(thisViewState.state);

        // console.log("thisBible: ", thisBible);
        let savedCategories = getLocalCategories(thisBible?.id);    
        if (savedCategories) {
            categoryList.current = savedCategories;
        }
        

    }, []);   // Run only the first time the app is mounted
   
    //-----------------------------------------------------------------------------
    // Update isStorying only when currentBible changes
    //-----------------------------------------------------------------------------
    useEffect(() => {
        const isStory = currentBible?.id?.substring(3, 6) === "STO";
        console.log("isStorying currentBible: ", currentBible?.id, isStory);
        setIsStorying(isStory);
        if (isStory) {
            setStoryMode(STORY_MODE);
        }

        // categoryList
        let savedCategories = getLocalCategories(currentBible?.id);    
        if (savedCategories) {
            categoryList.current = savedCategories;
        }
    }, [currentBible]); 

    // TBD - this may go away since Resources page is not used. TEMPORARY: pull from .env - this needs to use API and local settings similar to the bible settings
    const [currentOBTResource, setCurrentOBTResource] = useState({id:process.env.REACT_APP_RESOURCE_ID, name:process.env.REACT_APP_RESOURCE_NAME});
    
    // const [currentBook, setCurrentBook] = useState("GEN");
    // const [currentBookName, setCurrentBookName] = useState("The Beginning");
    const [currentBook, setCurrentBook] = useSessionStorage("currentBook", "");
    const [currentBookName, setCurrentBookName] = useSessionStorage("currentBookName", "");
    const [currentBookImage, setCurrentBookImage] = useSessionStorage("currentBookImage", "");
    const [currentBookNames, setCurrentBookNames] = useSessionStorage("currentBookNames", {[STORY_MODE]:"", [TRADITIONAL_MODE]:""});
    const [currentExtra, setCurrentExtra] = useSessionStorage("currentExtra", "");
    const [currentExtraName, setCurrentExtraName] = useSessionStorage("currentExtraName", "");
    const [bookType, setBookType] = useSessionStorage("bookType", "");
    
    const [currentResource, setCurrentResource] = useSessionStorage("currentResource","");
    const [currentResourceName, setCurrentResourceName] = useSessionStorage("currentResourceName","");
    const [currentMovement, setCurrentMovement] = useSessionStorage("currentMovement",1);
    const [currentMovementIds, setCurrentMovementIds] = useSessionStorage("currentMovementIds",[0]);
    const [currentMovementNames, setCurrentMovementNames] = useSessionStorage("currentMovementNames",[""]);
    const [currentChapter, setCurrentChapter] = useSessionStorage("currentChapter",1);
    const [currentChapterNames, setCurrentChapterNames] = useSessionStorage("currentChapterNames",[""]);
    const [currentSection, setCurrentSection] = useSessionStorage("currentSection",1);
    const [currentImage, setCurrentImage] = useSessionStorage("currentImage",null);
    
    const [noteMode, setnoteMode] = useState(NOTE_MODE_CLOSED);
    const toggleNoteMode = () => setnoteMode(noteMode===NOTE_MODE_CLOSED?NOTE_MODE_OPENED:NOTE_MODE_CLOSED);

    const [navigateFrom, setNavigateFrom] = useState('books');

    const [currentTrack, setCurrentTrack] = useState();
    const [trackIndex, setTrackIndex] = useState(1);  // tracks are indexed from 1

    const [audioLoading, setAudioLoading] = useState(false);
    const [showPlayList, setShowPlayList] = useState(false);
    const toggleShowPlayList = () => setShowPlayList(!showPlayList);

    const [isOpenCategory, setIsOpenCategory] = useState(false);

    const [isOpenResource, setIsOpenResource] = useSessionStorage("isOpenResource",[{id:"",state:false}]);
    const [isOpenResourceLevel1, setIsOpenResourceLevel1] = useSessionStorage("isOpenResourceLevel",[{book_id:"",id:"",state:false}]);
    
    const [isOpenControls, setIsOpenControls] = useState(false);

    const fileDownLoader = useRef();

    const enableControls = useRef();
    const [isPlaying, setIsPlaying] = useState(false);    
    const [isOpenPlayer, setIsOpenPlayer] = useState(false);
    const [dimensions, setDimensions] = useState([]);

    return(
        <AppContext.Provider value={{
            storyMode, toggleMode,
            viewState, toggleViewState, setViewState,
            currentBible, setCurrentBible,
            currentOBTResource, setCurrentOBTResource,
            currentLanguage, setCurrentLanguage,
            currentBook, setCurrentBook,
            currentBookImage, setCurrentBookImage,
            currentBookName, setCurrentBookName,
            currentBookNames, setCurrentBookNames,
            currentMovement, setCurrentMovement,
            currentMovementIds, setCurrentMovementIds,
            currentMovementNames, setCurrentMovementNames,
            currentChapter, setCurrentChapter,
            currentChapterNames, setCurrentChapterNames,
            currentSection, setCurrentSection,
            currentResource, setCurrentResource,
            currentResourceName, setCurrentResourceName,
            noteMode, toggleNoteMode,
            navigateFrom, setNavigateFrom,
            currentExtra, setCurrentExtra,
            currentExtraName, setCurrentExtraName,
            currentImage, setCurrentImage,
            currentTrack, setCurrentTrack,
            trackIndex, setTrackIndex,
            audioLoading, setAudioLoading,
            showPlayList, setShowPlayList,
            toggleShowPlayList, 
            isOpenCategory, setIsOpenCategory,
            bookType, setBookType,
            isOpenResource, setIsOpenResource,
            isOpenResourceLevel1, setIsOpenResourceLevel1,
            isOpenControls, setIsOpenControls,
            fileDownLoader, enableControls,
            isPlaying, setIsPlaying, 
            isOpenPlayer, setIsOpenPlayer,
            dimensions, setDimensions,
            categoryList,isOBTHelps,isStorying,
            isAppleDevice,
            isAndroidDevice,
            segmentAnalytics,
            includeProxyAPI, setIncludeProxyAPI
            }}>

            {children}
        </AppContext.Provider>

    )
}
