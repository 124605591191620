
import React, { useRef, useState, useEffect } from "react";
import { ObtResourceLevel2 } from "../ObtResourceLevel2";
import { DisplayMessage } from "../../components/DisplayMessage/DisplayMessage";
import { pushSection,  pushMovementNote} from "../../utils/listFunctions.js";
import { getBibleId } from '../../utils/localSettings';
import { AppData } from "../../components/AppContext";
import { GetAllSections } from "../../utils/GetAllSections";
import { downloadAudio } from "../../utils/downloadFunctions.js";
import { VIEWSTATE_SMALL, BOOK_TYPE_EXTRAS } from '../../constants.js';
import "./style.css";

const placeholderCards = [  
  {
    id: 1,
    section: 1,
    title: "_"
  }
];

// This component applies only to OBT Helps
// It pulls all the sections for the selected parent chapter (as selected from a ObtResourceLevel1 card)
// It displays a series of OBTResourceLevel2 cards representing each section in the chapter
//
//    OBT Resource Hierarchy:
//    Home -> Resources (get books) -> ResourceCard (Book UI) 
//            -> ResourceChapters (get chapters) -> ResourceLevel1 (Chapter UI) 
//                -> ResourceSections (get sections) -> ResourceLevel2 (Section UI)
// 
export const ObtResourceSections = ({ className, bookId, parentImage, parentCode, bookType, cardType }) => {
  const viewState=VIEWSTATE_SMALL;
  const { fileDownLoader, isOBTHelps } = AppData();
  const bibleId = getBibleId();
  const isExtras = bookType === BOOK_TYPE_EXTRAS;
  const parent = isExtras ? "movements" : "chapters";
  // const parent = cardType;

  /* List cards will track playable items and will be formatted as 
          {
              "key": unique key for the book
              "id": id for this type of card as returned by the API
              "index": index in the playlist,
              "section": section index in the playlist (may not be used)
              "sectionId": section id
              "title": title / name
              "passageAudio": list of audio files - filtered out chapter/title categories if disabled
              "passageAudioUnfiltered": list of all audio files regardless of category filters
              "passageFileName": list of audio file names - filtered out chapter/title categories if disabled
              "passageFileNameUnfiltered": list of all audio file names regardless of category filters
              "image": image file,
              "audioTitle": title audio,
              "passage": if note, this is the passage number
              "category": category name for notes,
              "categoryId": category id for notes,
              "color": color for note category,
              "duration": play duration
              "show":true/false to show this card - used to filter notes by categories
            }
  */
  const passageListRef = useRef([]);
  const notesListRef = useRef([]);
  const cardTrackIndex = useRef(0);   // Count sections and tracks
  const [notesList, setNotesList] = useState([]);
  const [passageList, setPassageList] = useState([]);
  
  const { sections, notes, isPending, isError, error } = GetAllSections(bookId, parent, parentCode, null, null, bookType, isOBTHelps);  
    
  // Temp for testing loading effects ...
  // let isPending = true, sourceId="JON", sections = [], isError = false, error = null;
    
  // Error Handling
  let message = "";
  if (isError) {
    message = `Unable to fetch ${parent}`;
    if (error && error.response && error.response.status && [403,404,500].includes(error.response.status)) {
      message = "Select a Language and Bible from the menu to view the books."
    }
    if (error && error.message) {
      if (error.message.includes("Network Error")) {
        message = "Unable to connect to the server. Please check your internet connection."
      } else {
        message = `Unable to fetch ${parent}: ${error.message}`;
      }
    }
  }

  /**
   * 
   * @param {*} key : unique key to identify the resource
   * Downloads only the selected resource - used for individual notes
   */
  const downloadResource = async (key) => {

    // Lookup the passages for the desired key 
    //  - may be either a scripture section with multiple passages or a single note
    let filteredList = passageList.filter((card) => card.key === key);
    if (filteredList.length < 1) {
      filteredList = notesList.filter((card) => card.key === key);
    }

    downloadAudio('list', fileDownLoader.current, filteredList, bibleId, null)
  } 

  /**
   * 
   * @param {*} key - Key of resource card
   * If it is a section, all of the related notes will also be downloaded
   */
  const downloadSection = async (key) => {    
    let filteredList = [];
    let isNote = false;
    let sectionIndex = null;
    // Lookup the passages for the section in the passageList and notesList        
    let filteredList1 = passageList.filter((card) => card.key === key);
    if (filteredList1.length < 1) {
      isNote = true;
      filteredList = notesList.filter((card) => card.key === key);
    } else {
      // Pull all notes for the section 
      sectionIndex = filteredList1[0].section;
      let filteredList2 = notesList.filter((card) => card.section === sectionIndex);
      filteredList = filteredList1.concat(filteredList2);
    }

    if (filteredList.length > 0) {
      let downloadName = "";
      if (filteredList[0].title) {
        downloadName = filteredList[0].title;
      } else {        
        downloadName = isNote ? "Note-"+key : "Sect-"+sectionIndex;
      }
      
      if (isNote) {
        // single note
        downloadAudio('list', fileDownLoader.current, filteredList, bibleId, null);
      } else {    
        // whole section    
        downloadAudio('section', fileDownLoader.current, filteredList, bookId, downloadName);
      }
    }
  } 

  const loadNotes = () => {
    if (!isPending && notes && notes.length > 0) {
      // console.log("Loading notes", notes);
      notes.forEach(card => {
        cardTrackIndex.current++;
        pushMovementNote(card,passageListRef.current,cardTrackIndex.current,bibleId,bookId,parent,parentCode,parentImage);
        // console.log("Movement notes:", passageListRef.current);
      });
    }
  }

  const loadPassages = () => {
    if (!isPending && sections && sections.length > 0) {
      // Only extras and chapters need to be full loaded.  
      // If this is a movement, then we only want the movement notes, which will be loaded separately
      if (isExtras || cardType === "chapter" ) {
      // console.log("Loading sections", sections);
      sections.forEach(card => {
        cardTrackIndex.current++;
        pushSection(card,passageListRef.current,notesListRef.current,cardTrackIndex.current,bibleId,bookId,parent,parentCode,parentImage,isOBTHelps);
        // console.log("Section passages:", passageListRef.current);
      });
    }
    }
  }
  const fcnLoadNotes = useRef();
  const fcnLoadPassages = useRef();
  fcnLoadNotes.current = loadNotes;
  fcnLoadPassages.current = loadPassages;

  /**
   * useEffect to preload notes and sections before remdering
   */
  useEffect(() => {
    const loadData = async () => {
        notesListRef.current = [];
        passageListRef.current = [];
        await fcnLoadNotes.current();
        await fcnLoadPassages.current();
        setNotesList(notesListRef.current);
        setPassageList(passageListRef.current);
    }
    if (!isPending) {
      loadData();
    }
  }, [sections, notes, isPending, isExtras, cardType]);

  // console.log(`resource-sections-${bookId}-${parent}-${parentCode}`);  
  return (
    <div className={`resource-sections ${viewState} ${className}`} key={`resource-sections-${bookId}-${parent}-${parentCode}`}>
      <div className="frame-resource-sections" key={`frame-resource-sections-${bookId}-${parent}-${parentCode}`}>

      {isError && <DisplayMessage message={`${message}`}/>}

        {/*------------------------------------------------------------------------------------------------
          IsPending -- Shimmer Animation until we have data
        ------------------------------------------------------------------------------------------------*/}
        {isPending && placeholderCards.map(card => {          
          return (
            <span className="skeleton-box" key={`shimmer-resource-sections-${bookId}-${parent}-${parentCode}-${card.id}`}>
              <ObtResourceLevel2
                key={`shimmer-${bookId}-Chapter-${card.id}`}
                cardId={card.id}
                cardType="section"
                cardName={card.title}
                cardAnimation = "shimmer"
                cardDuration={null}
                cardImageClassName={`card-image-${viewState}`}
                cardAudioTitle={null}
                showPlay={false}
                showDownload={false}
                playAudioOnClick={false}
                showAddToPlayList={false}
              />
            </span>
          )
        })}

        {/*------------------------------------------------------------------------------------------------
          Render the passages and notes for each section 
        ------------------------------------------------------------------------------------------------*/}
        {/* {!isPending && passageList && passageList.length > 0 &&
          <div className="passage-heading">Scripture Translation</div>
        } */}

        {!isPending && passageList && passageList.map(card => {
            let thisKey = card.key
            // console.log("ResourceSections - passageList - thisKey: ", thisKey)
            // Get all the notes for this section
            let sectionNotes = [];
            if (notesList && notesList.length > 0) {
              sectionNotes = notesList.filter(note => note.section === card.section)
            }
            
            return (
              <div className="obtresourcesection" key={`section-main-${thisKey}`}>
                {/* {!isExtras && parent === "chapters" && <div className="passage-heading" key={`section-heading-${thisKey}`}>Scripture Translation </div> } */}
                <ObtResourceLevel2
                key={card.key}
                cardKey={card.key}
                className="resource-section-link-instance"
                cardBookId={bookId}
                cardId={card.id}
                cardType="section"
                cardName={card.title}
                cardIndex={card.index}
                cardSection={card.section}
                cardSectionId={card.sectionId}
                cardDuration={card.duration}
                cardImage={card.image}
                cardColor={card.color}
                cardAudioTitle={card.audioTitle}
                cardPassageAudio={card.passageAudio}
                cardPassageFileName={card.passageFileName}
                cardPassage={card.passage}
                cardCategory={card.category}
                cardCategoryId={card.categoryId}
                cardNotes={sectionNotes}
                showPlay={true}
                showCard={card.show}
                showDownload={true}
                showAddToPlayList={true}
                playAudioOnClick={true}
                downloadAction={()=>{downloadSection(card.key)}}
              />
              {sectionNotes.length > 0 &&
                <>          
                  <div className="resources audio-note-heading" key={`resources-heading-${thisKey}`}>Resources</div>
                  {sectionNotes.map(note => {                    
                    return ( 
                      <ObtResourceLevel2
                      key={note.key}
                      cardKey={note.key}
                      className="resource-link-instance"
                      cardBookId={bookId}
                      cardId={note.id}
                      cardType="note"
                      cardName={note.title}
                      cardIndex={note.index}
                      cardSection={note.section}
                      cardSectionId={note.sectionId}
                      cardDuration={note.duration}
                      cardImage={note.image}
                      cardColor={note.color}
                      cardAudioTitle={note.audioTitle}
                      cardPassageAudio={note.passageAudio}
                      cardPassageFileName={note.passageFileName}
                      cardPassage={note.passage}
                      cardCategory={note.category}
                      cardCategoryId={note.categoryId}
                      showPlay={true}
                      showCard={note.show}
                      showDownload={true}
                      showAddToPlayList={true}
                      playAudioOnClick={true}
                      downloadAction={()=>{downloadResource(note.key)}}
                    />
                    )
                  })}
                </>
              }

              {!isExtras && sectionNotes.length > 0 && (<div key={`section-blanks-${card.key}`}>
                <br/>
              </div>
              )}
              
            </div>
              
          )  
          
        })}
        
      </div>      
    </div>
  );
};
