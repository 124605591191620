import { isSpecialUseCategory } from "./categoryFunctions.js";

  // Strip out only scripture passages to pass to the audio card
  export const filterPassages = (passages, noFilter=false) => {
    // console.log("filterPassages passages", passages)
    let audiofiles = [];
    let audiofile = null;
    for (let i = 0; i < passages.length; i++) {
        audiofile = null;
        if (passages[i] && passages[i].audio && passages[i].audio.length>0) {
          if (noFilter) {
            audiofile = passages[i].audio[0].url;
          } else {
            if (passages[i].obt_type === "scripture"
                // SpecialUse categories such as "chapter" or "title" may be filtered out by user
                || isSpecialUseCategory(passages[i].obt_type) ) {
                audiofile = passages[i].audio[0].url;
            }
          }
        }
        if (audiofile) {
          audiofiles.push(audiofile);
        }  
    }
    // console.log("filtered audio files", audiofiles);  
    return audiofiles;
  }


  export const filterPassageNames = (passages, noFilter=false) => {
    // console.log("filterPassageNames passages", passages)
    let filenames = [];
    let audiofile = null;
    for (let i = 0; i < passages.length; i++) {
        audiofile = null;        
        if (passages[i] && passages[i].audio && passages[i].audio.length>0) {
          if (noFilter) {
            audiofile = passages[i].audio[0].audio_filename;
          } else {
            if (passages[i].obt_type === "scripture"
                // SpecialUse categories such as "chapter" or "title" may be filtered out by user
                || isSpecialUseCategory(passages[i].obt_type) ) {
                audiofile = passages[i].audio[0].audio_filename;
            }
          }
        }
    
        if (audiofile) {
          filenames.push(audiofile);
        }  
    }
    // console.log("filtered audio files", filenames);  
    return filenames;
  }

  /**
   * 
   * @param {} card : section card - in format of API payload for an element of sections[]
   * @returns newPassages : array of concatenated passages
   */
  export const concatenateAudioNotes = (card) => {
    // Since all passages for a section are conmbined into one audio file, we have to combine all the audio notes also into 
    // a single grouping, so we'll use a temporary single passage for storing all the notes under the section
    let newPassages = [];
    if (card.passages.length > 0) {
      newPassages[0] = JSON.parse(JSON.stringify(card.passages[0]));
      //console.log("newPassages[0]:", newPassages);
      let combinedNotes = [];
      if (newPassages[0].audio_notes === undefined) {
        newPassages[0].audio_notes = [];
      } else {
        //Combine all the audio notes for the section under the first passage       
        combinedNotes = JSON.parse(JSON.stringify(newPassages[0].audio_notes)); 
      }
      
      for (let i = 1; i < card.passages.length; i++) {
        if (card.passages[i]?.audio_notes) {
          if (card.passages[i].audio_notes !== undefined) {
            combinedNotes = combinedNotes.concat(card.passages[i].audio_notes);
          }
        }
      }

      if (combinedNotes) {
        //console.log("combinedNotes:", combinedNotes);
        newPassages[0].audio_notes = JSON.parse(JSON.stringify(combinedNotes));
      }
      //console.log("newPassages:", newPassages);
      return newPassages;
    } 
  }


 /**
 * 
 * @param {*} isOBTHelps - true if this is OBTHelps, false otherwise
 * @param {*} card : section card - in format of API payload for an element of sections[] 
 * @returns String representing the section title.  If this isOBTHelps the verse range is appended to the title text.
 * @example  S2 - Gabriel Visits Zechariah - 1:5 - 1:21
 * 
 * Special processing must loop through all the passages in order to pull the first "chapter_start":"verse_start" thru last "chapter_end":"verse_end" 
 * Ignore any chapter: 0
 */
export const buildSectionTitle = (isOBTHelps, card) => {  
  let finalTitle = "";
  let title = card.title;
  let section = card.section;
  let range = getSectionVerseRange(card);
  
  if (title !== undefined && title !== null ) {    
    finalTitle = title + (isOBTHelps && range?" - " + range:"");
  } else { 
    finalTitle = section?"Section " + section:"Verses"  + range?" - " + range:"";
  }
  return finalTitle;
}

/**
 * 
 * @param {*} card : section card - in format of API payload for an element of sections[] 
 * @returns String representing the section title.  If this isOBTHelps the verse range is appended to the title text.
 * @example  S2 - Gabriel Visits Zechariah - 1:5 - 1:21
 * 
 * Special processing must loop through all the passages in order to pull the first "chapter_start":"verse_start" thru last "chapter_end":"verse_end" 
 * Ignore any chapter: 0
 */
export const getSectionVerseRange = (card) => {
  let chapter_start = null;
  let chapter_end = null;
  let verse_start = null;
  let verse_end = null;
  let passages = card.passages;
  let range = null;  
  for (let i = 0; i < passages.length; i++) {    
    if (passages[i] && passages[i].chapter_start && passages[i].chapter_start !== 0 && passages[i].obt_type === "scripture") {
        // First occurrences for start
        chapter_start = chapter_start ? chapter_start : passages[i].chapter_start;
        verse_start = verse_start ? verse_start : passages[i].verse_start;
        // Last occurrences for end
        chapter_end = passages[i].chapter_end;
        verse_end = passages[i].verse_end;
    }
    if (chapter_start && verse_start && chapter_end && verse_end ) {
      if ( chapter_start !== chapter_end) {
        range = `${chapter_start}:${verse_start}-${chapter_end}:${verse_end}`;
      } else {
        range = `${chapter_start}:${verse_start}-${verse_end}`;
      }
    }  
  }
  return range;
}