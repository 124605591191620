import React, { useRef, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./style.css";

export const PopupMessage = ({ message, isVisible, onClose }) => {
  const messageRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        messageRef.current &&
        !messageRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    if (isVisible) {
      // Delay adding the event listener until after the component is rendered
      setTimeout(() => {
        document.addEventListener("click", handleClickOutside);
      }, 0);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return (
    <div className="popup-message-container"
      ref={messageRef}      
      onClick={(event) => {
        event.stopPropagation(); // Prevent closing when clicking on the message
        onClose();
      }}
    >
      {/* <AiOutlineExclamationCircle className="popup-message-icon left" /> */}
      <AiOutlineCloseCircle className="popup-message-icon left" />
      {message}
    </div>
  );
};

