import React, { useEffect } from "react";
import { AudioPlayListCard } from "../AudioPlayListCard/index.js";
import { AppData } from "../AppContext/index.jsx";
import { PlayListData } from "../../components/AudioPlayListContext";
import { STORY_MODE } from '../../constants.js';
import "./style.css";

export const AudioPlaylist = ( {
    bookId, 
    dataBreakpointsMode    
  } ) => { 

    // Fetch data for all sections to load into player tracks
  const { storyMode, currentBible, setCurrentSection, enableControls, setIsPlaying, trackIndex, currentSection } = AppData();
  const parent = (storyMode === STORY_MODE)?"movements":"chapters";
  
  const { playList, dispatch, playListRef } = PlayListData();

  const clearAllPlayList = () => {
    // console.log("clearAllPlayList");
    enableControls.current = false;
    setIsPlaying(false)
    dispatch({ type: 'CLEAR_ALL'});
  };

  useEffect(() => {
    dispatch({ type: 'GET' });
  }, [currentBible, dispatch]);

  let thisKey = "";
  let firstSrc = null;
  return (
      <div className={`audio-playlist ${dataBreakpointsMode}`}>

        <>
          <div className={`header-frame ${dataBreakpointsMode}`}>
          <span className="audio-playlist-header-text">Playlist</span>
          <span className="audio-playlist-header-controls">
            <span className="audio-playlist-clear-all" onClick={clearAllPlayList}>Clear All</span>
          </span>
          </div>

          <div className={`frame-play ${dataBreakpointsMode}`}>          
          
          {playList && playList.map(card => {
            if (firstSrc === null) {
              // Set to the first section that has passages
              firstSrc = card.cardPassage;
            }
            // console.log("card", card);
            thisKey = `${card.cardBook}-${parent}-${card.id}-Playcard-${card.cardSection}`;
            return (
              // <span key={`span-${thisKey}`} className={`audioplaylist Section-${card.cardSection}`} ref={currentSection === card.cardSection?playListRef:null}>
              <span key={`span-${thisKey}`} className={`audioplaylist Section-${card.cardSection}`} ref={trackIndex === card.cardIndex?playListRef:null}>
                <AudioPlayListCard
                  key={thisKey}
                  cardId={card.id}
                  cardShow={card.cardShow}
                  className = {`audio-playlist-instance ${trackIndex === card.cardIndex? "active-track" : "inactive-track"}`}
                  cardRef={currentSection === card.cardSection?playListRef:null}
                  cardType = {card.cardType}
                  cardMode = {card.cardMode}
                  cardBook = {card.cardBook}
                  cardParent = {card.cardParent}
                  cardSection = {card.cardSection}
                  cardIndex = {card.cardIndex}
                  cardName = {card.cardName}
                  cardDuration={card.cardDuration}
                  cardColor={card.cardColor}
                  cardCategory={card.cardCategory}
                  cardPassage={card.cardPassage}
                  cardImageClassName = "playerListCard"
                  cardImage={card.cardImage}
                  cardAudioTitle={card.cardAudioTitle}
                  cardPassageAudio={card.cardPassageAudio}
                  cardState = "small"
                  playAudioOnClick={true}
                  setCurrentCardAction = {() => setCurrentSection}
                />
              </span>
            )
          })}

          </div>
        </>

      </div>
  );
};
