// resourceOpenFunctions.js

export const toggleResourceOpen = (book_id, bookName, bookImage, setCurrentBook, setCurrentBookImage, setCurrentBookName, isOpenResource, setIsOpenResource) => {
  try {
    let found = false;
    let newRes = isOpenResource.map(book => {
      if (!found && book.id === book_id) {
        // Toggle the state
        found = true;
        return { ...book, state: book.state?!book.state:true };
      } else {
        // No changes
        return book;
      }
    });
    if (!found) {
      // Add new book
      newRes.push({ id: book_id, state: true });
    }
    setCurrentBook(book_id);
    setCurrentBookName(bookName);
    setCurrentBookImage(bookImage);
    setIsOpenResource(newRes);
  } catch (e) {
    console.error("Error in toggleResourceOpen: ", e);
  }
}

export const getResourceOpen = (book_id, isOpenResource, setIsOpenResource) => {
  try {
    // console.log("getResourceOpen ", book_id);
    let newRes = isOpenResource;
    let thisRes = newRes.find(book => book.id === book_id);
    // console.log("thisRes",thisRes)
    if (thisRes) {
      return thisRes.state?thisRes.state:false;
    } else {
      // If not yet in list, add it as false so that all books are saved for later use in expand/collapse all
      // This should only happen the first time the component is rendered
      newRes.push({ id: book_id, state: false });
      setIsOpenResource(newRes);
      return false;
    }
  } catch (e) {
    console.error("Error in getResourceOpen: ", e);
  }
}

export const setResourceOpen = (book_id, expandFlag, isOpenResource, setIsOpenResource) => {
  try {
    // console.log("setResourceOpen ", book_id);
    let newRes = [];
    if (book_id === 'ALL') {
      newRes = isOpenResource.map(book => {
          return { ...book, state: expandFlag };
      });    
      setIsOpenResource(newRes);
    } else {
      // Strip out the old entry for the book then push the new one set to TRUE
      newRes = isOpenResource.filter(book => book.id !== book_id);
      newRes.push({ id: book_id, state: expandFlag });
      setIsOpenResource(newRes);
    }
  } catch (e) {
    console.error("Error in setResourceOpen: ", e);
  }
}

export const toggleResourceLevel1Open = (book_id, card_id, isOpenResourceLevel1, setIsOpenResourceLevel1) => {
  try {
    // console.log("toggleResourceOpen: book_id",book_id,"card_id",card_id);
    let found = false;
    let newRes = isOpenResourceLevel1.map(card => {
      if (!found && card.book_id === book_id && card.id === card_id) {
        // Toggle the state
        found = true;
        return { ...card, state: card.state?!card.state:true };
      } else {
        // No changes
        return card;
      }
    });
    if (!found) {
      // Add new book
      // console.log("toggleResourceOpen: newRes",newRes);
      newRes.push({ book_id: book_id, id: card_id, state: true });
    }
    
    // console.log("setisOpenResourceLevel1",newRes);
    setIsOpenResourceLevel1(newRes);
  } catch (e) {
    console.error("Error in toggleResourceLevel1Open: ", e);
  }
}

export const getResourceLevel1Open = (book_id, card_id, isOpenResourceLevel1, setIsOpenResourceLevel1) => {
  try {
    // console.log("getResourceOpen book_id, card_id", book_id,card_id);
    // console.log("isOpenResourceLevel1", isOpenResourceLevel1);
    if (book_id && card_id) {
      let newRes = isOpenResourceLevel1;
      let thisRes = null;
      if (newRes && newRes.length>0 ) {
        thisRes = isOpenResourceLevel1.find(card => card.book_id === book_id && card.id === card_id);
        // console.log("thisRes",thisRes)
      }
      if (thisRes) {
        return thisRes.state?thisRes.state:false;
      } else {
        // If not yet in the list, add it as false so that all items can be referenced in expand/collapse all
        newRes.push({ book_id: book_id, id: card_id, state: false });
        // console.log("setIsOpenResourceLevel1 newRes",newRes);
        setIsOpenResourceLevel1(newRes);
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    console.error("Error in getResourceLevel1Open: ", e);
  }
} 

export const setResourceLevel1Open = (book_id, card_id, expandFlag, isOpenResourceLevel1, setIsOpenResourceLevel1) => {
  try {
    // console.log("setResourceLevel1Open ", book_id, card_id);
    let newRes = [];
    if (card_id === 'ALL') {
      // Get only the cards for this book
      let BookRes = isOpenResourceLevel1.filter(card => card.book_id === book_id );
      // console.log("BookRes:", BookRes);
      if (BookRes) {
        newRes = BookRes.map(card => {
          return { ...card, state: expandFlag };
        });    
        setIsOpenResourceLevel1(newRes);
      }
    } else {
      // Strip out the old entry for the item then push the new one set to TRUE
      newRes = isOpenResourceLevel1.filter(card => card.book_id !== book_id || card.id !== card_id);
      newRes.push({ book_id: book_id, id: card_id, state: expandFlag });
      setIsOpenResourceLevel1(newRes);
    }
  } catch (e) {
    console.error("Error in setResourceLevel1Open: ", e);
  }
}