
import React, { useEffect } from "react";
import { ElementCard } from "../ElementCard";
import { DisplayMessage } from "../../components/DisplayMessage/DisplayMessage";
import { AppData } from "../../components/AppContext";
import { GetAllChapters } from "../../utils/GetAllChapters";
import "./style.css";

const placeholderCards = [  
  {
    chapter: 1,
    title: "_"
  },
  {
    chapter: 2,
    title: "_"
  }
];

export const updateChapterNames = (chapters, setCurrentChapterNames, type=null) => {
  // console.log('chapters rendering: ', chapters);
  if (chapters && chapters.length > 0) {
    let chapterList = [];
    for (let card in chapters) {
      chapterList[chapters[card].chapter] = chapters[card].title;
    }
    // console.log('chapterList', chapterList);
    setCurrentChapterNames(chapterList);
  }
}

export const Chapters = ({ className }) => {
  const { storyMode, viewState, currentBook, setCurrentChapter, currentBookImage, setCurrentChapterNames, isOBTHelps } = AppData();
  const { bookId, chapters, isPendingChapters, isErrorChapters, errorChapters } = GetAllChapters(currentBook, isOBTHelps);

  useEffect(() => {
      if (!isPendingChapters) {
        updateChapterNames(chapters, setCurrentChapterNames);
      }
            
    },[isPendingChapters, chapters, setCurrentChapterNames]);
    
  let message = "";
  if (isErrorChapters) {
    let error = errorChapters;
    message = "Error fetching chapters.";         
    if (error && error.response && error.response.status && [403,404,500].includes(error.response.status)) {
      message = "Select a Language and Bible from the menu to view the books."
    }
    if (error && error.message) {
      if (error.message.includes("Network Error")) {
        message = "Unable to connect to the server. Please check your internet connection."
      } else {
        message = error.message;
      }
    }
  }

  return (
    <div className={`chapters ${storyMode} ${viewState} ${className}`}>
      <div className="frame-chapters">

        {isErrorChapters && <DisplayMessage message={`${message}`}/>}

        {isPendingChapters && placeholderCards.map(card => {          
          return (
            <span className="skeleton-box" key={card.chapter}>
              <ElementCard
                key={`${currentBook}-Movement-${card.chapter}`}
                cardType="chapter"
                cardName={card.title}
                cardAnimation = "shimmer"
                cardIndex={card.chapter}
                cardDuration={null}
                cardImageClassName={`card-image-${viewState}`}
                cardAudioTitle={null}
                cardState={viewState}  
                showPlay={false}
                showDownload={false}
                obtDownloadClassName={`card-download-${viewState}`}
                obtQuickPlayClassName={`card-quickplay-${viewState}`}
                overlapGroupClassName="instance-node"
                subheadingClassName="element-card-subheading"
                showAddToPlayList={false}
              />
            </span>
          )
        })}

        {!isPendingChapters && !isErrorChapters && chapters && chapters.map(card => {
              return (
              <ElementCard
                key={`${bookId}-Chapter-${card.chapter}`}
                cardType="chapter"
                cardId={card.id}
                cardName={card.title}
                cardIndex={card.chapter}
                cardDuration={card.duration}
                cardMovement={card.movement_start}
                cardImageClassName={`card-image-${viewState}`}
                cardImage={card.images[0]?card.images[0].url?card.images[0].url:currentBookImage:currentBookImage}
                cardAudioTitle={card.title_audio?card.title_audio[0]?.url?card.title_audio[0]?.url:null:null}
                cardState={viewState}
                showPlay={false}
                showDownload={false}
                obtDownloadClassName={`card-download-${viewState}`}
                obtQuickPlayClassName={`card-quickplay-${viewState}`}
                overlapGroupClassName="instance-node"
                subheadingClassName="element-card-subheading"                 
                navigateTo="/sections" 
                setCurrentCardAction={() => setCurrentChapter}
                showAddToPlayList={true}
                imageOverlay={card.images[0]?(card.images[0].url?null:card.chapter):card.chapter}
              />
              )
            })}
      </div>      
    </div>
  );
};
