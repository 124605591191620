import { segmentAnalytics } from "../components/AppContext";
import { getBibleId } from "./localSettings.js";

export const trackPage = (pageName, mode="", book="", parent="", parentName="") => {
  const userAgent = window.navigator.userAgent;

  // console.log("trackPage", pageName, {
  //   userAgent: userAgent,
  //   bible:getBibleId(),
  //   book:book??"",
  //   mode:mode??"",
  //   parent:parent??"",
  //   parentName:parentName??""
  // });

  segmentAnalytics.page(pageName, {
    userAgent: userAgent,
    bible:getBibleId(),
    book:book??"",
    mode:mode??"",
    parent:parent??"",
    parentName:parentName??""
  });
}

export const trackCardStart = (action="", book="", mode="", type="", category="", parent="", section="", name="") => {
  
  // console.log("trackCardStart", {
  //   action: action??"",
  //   bible:getBibleId(),
  //   book:book??"",
  //   mode:mode??"",
  //   type:type??"",
  //   category:category??"",
  //   parent:parent??"",
  //   section:section??"",
  //   name:name??""
  // })

  segmentAnalytics.track("Play Start", {
    action: action??"",
    bible:getBibleId(),
    book:book??"",
    mode:mode??"",
    type:type??"",
    category:category??"",
    parent:parent??"",
    section:section??"",
    name:name??""
  });
}

export const trackCardEnd = (action="", book="", mode="", type="", category="", parent="", section="", name="", duration=0) => {
  if (duration === undefined || duration === null || duration < 0) {
    duration = 0;
  }
  const roundedDuration = duration.toFixed(2);

  // console.log("trackCardEnd", {
  //   action: action??"",
  //   bible:getBibleId(),
  //   book:book??"",
  //   mode:mode??"",
  //   type:type??"",
  //   category:category??"",
  //   parent:parent??"",
  //   section:section??"",
  //   name:name??"",
  //   duration: roundedDuration??0
  // })

  segmentAnalytics.track("Play End", {
    action: action??"",
    bible:getBibleId(),
    book:book??"",
    mode:mode??"",
    type:type??"",
    category:category??"",
    parent:parent??"",
    section:section??"",
    name:name??"",
    duration: roundedDuration??0
  });
}
