import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getModeParm }  from './getModeParm';
import { getBible } from './localSettings';
import { BOOK_TYPE_RESOURCES, BOOK_TYPE_EXTRAS } from '../constants.js';

export const GetAllSections = ( bookId, parent, parentCode, resourceId, navFrom, bookType, isOBTHelps, queryEnabled=true ) => {  
    // console.log("GetAllSections - queryEnabled:", queryEnabled, "bookId:", bookId, "parent:", parent, "parentCode:", parentCode, "resourceId:", resourceId, "navFrom:", navFrom, "bookType:", bookType, "isOBHelps:", isOBTHelps);  
    const biblesPath = isOBTHelps?'helps' : 'bibles';
    const extrasPath = process.env.REACT_APP_EXTRAS_PATH?process.env.REACT_APP_EXTRAS_PATH:'bibles';
    let sections = [];
    let notes = [];
    const { bibleId, isBibleBrain } = getBible();
    const isExtras = (bookType === BOOK_TYPE_EXTRAS);
    let sourceId = (navFrom === BOOK_TYPE_RESOURCES) ? resourceId : bookId;  
    let parentKey = (navFrom === BOOK_TYPE_RESOURCES) ? navFrom : parent;
    let {isMVP, modeParm} = getModeParm();
    let indexParm = (parentCode)?parentCode:"1";
    let url = '';
    const isEnabled = queryEnabled && !!bibleId && !!sourceId && !!parent; // Only run when URL parms are truthy,
    
    if (isExtras) {
      // Use "General" endpoint for extras
      url = `${process.env.REACT_APP_BASE_URL}/${extrasPath}/${bibleId}/general/${sourceId}/${parent}/${indexParm}${modeParm}`
    } else {
      if (isBibleBrain) {
        //url = `${process.env.REACT_APP_PROXY_URL}/${parent}/${bibleId}/${bookId}/${indexParm}`
        url = `${process.env.REACT_APP_PROXY_URL}/bibles/${bibleId}/${sourceId}/${parent}/${indexParm}${modeParm}`
      } else {
        // Example of API endpoint to get all sections for first movement of Genesis:  /bibles/ENGSEB/GEN/movements/1
        url = `${process.env.REACT_APP_BASE_URL}/${biblesPath}/${bibleId}/${sourceId}/${parent}/${indexParm}${modeParm}`
      }
    }
    // console.log("GetAllSections", url);
    // console.log("GetAllSections queryKey: bibleId,bookType,sourceId,parentKey,parentCode", bibleId,bookType,sourceId,parentKey,parentCode)
    let { data, isPending, isError, error, isFetching, isFetched } = useQuery({
        queryKey: [bibleId,bookType,sourceId,parentKey,parentCode],
        queryFn: () => axiosClient.get(url).then((res) => res.data),
        enabled: isEnabled,
        staleTime: 1000 * 60 * 30, // cache for 30 minutes
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });  

  if (!isPending) {
    // console.log("isBibleBrain", isBibleBrain, "data", data);
    if (data && data.data) {
      if (isMVP) {
        if (data.data.sections) {
          sections = data.data.sections;
        } else if (data.data[0] && data.data[0].movements && data.data[0].movements[0] && data.data[0].movements[0].sections) {
          // For Resources
          sections = data.data[0].movements[0].sections;
        }
      } else {
        // console.log("data.data[parent]", data.data[parent]);
        if (data.data[parent]) {
          let parents = data.data[parent];
          if (parents.length > 0) {
            sections = data.data[parent][0].sections;
            // console.log("sections", sections);
            if (sections && sections.length > 1) {
              sections.sort((a, b) => a.section - b.section);
            }
            // Some movements have audio notes without a section - these are movement level notes and have to be treated differently
            notes = data.data[parent][0].audio_notes;
            if (notes && notes.length > 1) {
              notes.sort((a, b) => a.passage - b.passage);
            }
          }
        }
      }     
    } else {
      isError = true;
      error = new Error("No data available for this request.");
    }
    
  }

  if (isFetching || !isFetched) {
    // console.log("GetAllSections - isPending:" , isPending, "queryEnabled:" , isEnabled, "isFetching:" , isFetching, "isFetched:" , isFetched, 
    //   "bookId", bookId, "parent", parent, "parentCode", parentCode, "sections:" , sections);
  }

  // if (isError && isBibleBrain && parent === "movements") {
  //   error = new Error("Movements are not currently supported for this Bible.   Try switching to Traditional Mode.");
  // }
  // console.log("GetAllSections: ", sections );
  return { sourceId, sections, notes, isPending, isError, error }
}
