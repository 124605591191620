import React from "react";
import useWindowWidth from "../../hooks/useWindowWidth";
import { ObtHeader } from "../../components/ObtHeader";
import { ObtFooter } from "../../components/ObtFooter";
import { ObtBibleButton } from "../../components/ObtBibleButton";
import { ObtViewButtons } from "../../components/ObtViewButtons";
import { CopyrightOverlay } from "../../components/CopyrightOverlay";
import { Extras } from "../../components/Extras";
import { AudioPlaylist } from "../../components/AudioPlaylist";
import { StoryMode } from "../../components/StoryMode";
import { AppData } from "../../components/AppContext";
import "./style.css";

export const ExtrasPage = ( ) => {
  const { dataBreakpointsMode } = useWindowWidth();
  const { showPlayList, isOpenPlayer, currentBook } = AppData();
  const classPlayList = showPlayList ? "with-playlist" : "";
  const sourceId = currentBook;  
  
  return (
    <>
    <div className={`extras-page background-wallpaper ${dataBreakpointsMode} ${classPlayList}`} data-breakpoints-mode={dataBreakpointsMode}>

      <div className="left-wrapper">
        <div className="hero-header-section">
          <ObtHeader
            breakpoint={dataBreakpointsMode}
            className="obt-header-navigation-menu"
          />            
        </div>          
        
        <div className={`frame-tabs-container ${dataBreakpointsMode}`}>
          <div className={`frame-tabs frame-tabs-${dataBreakpointsMode}`}>
            <ObtBibleButton className='frame-tabs-left'/>
            
            {dataBreakpointsMode === "desktop" && <div className={`heading-3 frame-tabs-center ${dataBreakpointsMode}`}>EXTRAS</div>}

            <div className="horizontal-tabs">          
              <StoryMode className="story-mode-toggle" />
              <div className="button">
                {/* <ObtViewButtons className="obt-view-buttons" state={viewState} toggleState={toggleViewState}/> */}
                <ObtViewButtons className="obt-view-buttons" />
              </div>
            </div>
          </div>
        </div>

        {dataBreakpointsMode !== "desktop" && <div className={`heading-3 ${dataBreakpointsMode}`}>EXTRAS</div>}

        <Extras />

        <CopyrightOverlay />
      
        <div className="obt-footer">        
          <div className="container-footer">
              <ObtFooter  breakpoint={dataBreakpointsMode} />
          </div>
        </div>      
      </div>

      <div className="right-wrapper" >
        {dataBreakpointsMode === "desktop" && showPlayList &&
          <>
            {/* {isOpenPlayer && 
              <Portal setIsOpen={setIsOpenPlayer} dimensions={dimensions}>
                <AudioPlaylist 
                  bookId={sourceId}
                  dataBreakpointsMode={dataBreakpointsMode}                  
                  />       
              </Portal>
            } */}
            
            {!isOpenPlayer && 
              <div className="right-frame">
                <AudioPlaylist 
                  bookId={sourceId}
                  dataBreakpointsMode={dataBreakpointsMode}
                  />   
              </div>
            }
          </>
        }

        {dataBreakpointsMode !== "desktop" && showPlayList &&
          <AudioPlaylist 
          bookId={sourceId}
          dataBreakpointsMode={dataBreakpointsMode}
          />   
        }
        
      </div>
      {/* {downloaderComponentUI} */}
    </div>

      <div>
        <br/>
        <br/>
        <br/>
      </div>
        </>
  );
};
