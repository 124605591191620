import React, {useEffect, useState, useRef} from "react";
import { RxDownload, RxChevronDown, RxChevronUp } from "react-icons/rx";
import { ObtQuickPlay } from "../ObtQuickPlay";
import { PlayListAdd } from "../PlayListAdd";
// import { ObtDownload } from "../ObtDownload";
import { SpeakerAnimate } from "../SpeakerAnimate/index.js";
import { ObtResourceSections } from "../ObtResourceSections";
import { toggleResourceLevel1Open, getResourceLevel1Open } from '../../utils/resourceOpenFunctions.js';
import { TRADITIONAL_MODE } from '../../constants.js';
import { AppData } from "../AppContext/index.jsx";
import "./style.css";

// This component applies only to OBT Helps
// It displays a single card representing a chapter from ResourceChapters
//
//    On click the chapter will expand to display cards for each section in the chapter
//
//    OBT Resource Hierarchy:
//    Home -> Resources (books) -> ResourceCard -> ResourceChapters -> ResourceLevel1 -> ResourceSections -> ResourceLevel2
// 
export const ObtResourceLevel1 = ( {    
    className="resource-level1-instance",    
    cardKey=null,
    bookType=null,
    cardBookId=null,
    cardId=null,
    cardType=null,
    cardCode=null,
    cardAnimation=null,  // used for placeholder shimmer on loading
    cardImage,
    cardAudioTitle = "",
    notes=null,
    cardMode="small",
    cardName = "",
    cardDuration="",
    showPlay = false,
    obtQuickPlayClassName,
    showAddToPlayList=false,
    showDownload = false,  
    downloadAction=null    
}) => {
  // console.log("ObtResourceLevel1: cardType: ",cardType);
    
  const { isOpenResourceLevel1, setIsOpenResourceLevel1 } = AppData();
  
  const [thisOpenResource, setThisOpenResource] = useState(false);  // tracks the individual resource and triggers refresh on toggle (e.g., expand all)

  // Use a placeholder image unless this is an animation card
  const thisImage = !(cardAnimation||cardImage)?process.env.REACT_APP_PLACEHOLDER_IMAGE:cardImage;
  const cardAnimationClass = cardAnimation?cardAnimation:"";

  const clickActions = () => {
    // console.log("clickActions: clicked: ",cardBookId,cardCode);
    toggleResourceLevel1Open(cardBookId,cardCode,isOpenResourceLevel1, setIsOpenResourceLevel1)
  }

  const clickDownload = (event) => {
    const thisDownload = downloadAction?downloadAction():null;
    // console.log("clickDownload: ",thisDownload);
    if (thisDownload) {
      thisDownload();
    }
    event.stopPropagation();    
  }

  const quickPlayAction = (event) => {
    console.log("TBD:  quickPlayAction clicked ");
    event.stopPropagation();
  }

  const isThisOpen = () => {
    return getResourceLevel1Open(cardBookId,cardCode,isOpenResourceLevel1, setIsOpenResourceLevel1);
  }
  const fcnIsThisOpen = useRef();
  fcnIsThisOpen.current = isThisOpen;

  useEffect(() => {
    // Update context value here instead of during render
    setThisOpenResource(fcnIsThisOpen.current());
  }, [isOpenResourceLevel1]);

  return (
    <div className={`resource-level1 card-mode-${cardMode}`} >
      <div className="frame-card" >
          <div className="frame-overlay" onClick={clickActions}>
            <div className={`frame-image`} >
              {thisImage && <img className={`resource-level1-image ${cardMode}`} alt="resource-level1" src={thisImage} />}
            </div>
            
            <div className={`frame-title ${cardAnimationClass}`} >
              <SpeakerAnimate className={`title-icon-img ${cardAnimationClass}`} cardAudioTitle={cardAudioTitle} cardName={cardName}/>
              <div className="level1-card-name">{cardName}</div>              
            </div>
            <div className="level1-expand-wrapper">
              {showAddToPlayList && <PlayListAdd className={`level1-playlist-add`}
                                cardId={cardId}
                                cardType={cardType}
                                cardMode={TRADITIONAL_MODE}
                                cardBook={cardBookId}
                                cardParent={null}
                                cardSection={null}
                                cardName={cardName}
                                cardPassageAudio={null}
                                cardPassageFileName={null}
                                cardPassage={null}
                                cardCategory={null}
                                cardCategoryId={null}
                                cardDuration={null}
                                cardImage={cardImage}
                                cardAudioTitle={cardAudioTitle}
                                cardColor={null}
                                showCard={true}
                                cardNotes={null}
                                overrideDelete={false}
                              />}
              
              {thisOpenResource && showDownload && <RxDownload className={`level1-resource-download `} fill="rgb(235 241 249 / 50%)" onClick={(e) => clickDownload(e)}/>}

              {showPlay && <ObtQuickPlay className={`level1-resource-quickplay ${obtQuickPlayClassName}`} stateProp="default" duration={cardDuration} playAction={quickPlayAction}/>}

              {!thisOpenResource && 
                <RxChevronDown className="level1-expand-resource" />
              } 
              {thisOpenResource && 
                <>
                  <RxChevronUp className="level1-expand-resource" />
                </>
              }
            </div>
          </div>
          {/* If the card is open show the sections in a list */}
          {thisOpenResource &&
            <ObtResourceSections className="resource-level2" bookId={cardBookId} parentCode={cardCode} parentImage={cardImage} bookType={bookType} cardType={cardType}/>
          }
      </div>
    </div>
  );
};
