import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import { ElementCard } from "../ElementCard";
import { updateMovementNames } from "../Movements";
import { updateChapterNames } from "../Chapters";
import { DisplayMessage } from "../../components/DisplayMessage/DisplayMessage";
import { AppData } from "../../components/AppContext";
import { VIEWSTATE_CAROUSEL, BOOK_TYPE_RESOURCES } from '../../constants.js';
import { GetAllCards } from "../../utils/GetAllCards";

// Components and styles for Swiper Slider 
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectCoverflow, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';

import "./style.css";

export const CardSlider = ( {cardType} ) => {
    const [swiper, setSwiper] = useState();
    const previousCardType = useRef(cardType);

    const { currentBook, currentResource, currentMovement, setCurrentMovement, currentChapter, setCurrentChapter, setCurrentImage, bookType,
       currentBookImage, setCurrentMovementNames, setCurrentMovementIds, setCurrentChapterNames, isOBTHelps } = AppData();   
       
    const { sourceId, cards, isPending, isError, error } = GetAllCards(currentBook, currentResource, cardType, bookType, isOBTHelps);    

    useEffect(() => {
      const toggleSlider = (swiper, cardType, cards) => {
        const chapterMode = cardType === "chapter";
        // console.log("toggleSlider entered - cardType: ", cardType, "cards: ", cards);  
        if (swiper && cards && cards.length>0) {
          const codeName = (chapterMode)?"chapter":"movement";
          const currentCard = (chapterMode)?currentChapter:currentMovement;
          // Switch to the correct movement or chapter when the story mode toggles
          // console.log("toggleSlider - codeName: " + codeName);
          // console.log("toggleSlider - currentCard: " + currentCard);
          // console.log("toggleSlider - cards: ", cards);
          // Only change the slider if the toggle is in sync with the cards
          let exists = cards.some(card => codeName in card);
          if (exists) {
            let index = cards.findIndex(card => card[codeName] === parseInt(currentCard));  
            // console.log("toggleSlider index: ", index);
            if (index >= 0) {
              swiper.slideTo(index, 0);
            } else {
              // If something goes wrong, reset to the first slide
              setCurrentMovement(1);
              setCurrentChapter(1);
              swiper.slideTo(0, 0);
            }
          }
        }
      }
      // adjust the slider when story mode toggles between movements and chapters
      if (!isPending && swiper && cards && previousCardType.current !== cardType) {        
        toggleSlider(swiper, cardType, cards);
        previousCardType.current = cardType;
      }
    }, [cardType, isPending, cards, swiper, currentMovement, setCurrentMovement, currentChapter, setCurrentChapter]);

    useEffect(() => {
      if (!isPending && !isError) {
        if (cardType === "chapter") {
          updateChapterNames(cards, setCurrentChapterNames, "card");
        } else {
          updateMovementNames(cards, setCurrentMovementNames, setCurrentMovementIds, "card");
        }
      }
    },[isPending, isError, cards, cardType, setCurrentMovementNames, setCurrentMovementIds, setCurrentChapterNames]);

    const localSetSwiper = (swiperInstance) => {
      // console.log("localSetSwiper - swiperInstance: ", swiperInstance);
      setSwiper(swiperInstance);
    }

    function setCards(cardType, card) {
      // Whenever movement changes, keep chapter in sync and vice-versa
      // This will make it possible to toggle story mode without losing the user's place
      // console.log("setCards - card: ", card);
      if (card) {
        if (cardType === "movement") {
          setCurrentChapter(card.chapter_start?card.chapter_start:1);
          // console.log("setCards - movement - setCurrentChapter: " + card.chapter_start);
          setCurrentMovement(card.movement);
          // console.log("setCards - movement - setCurrentMovement: " + card.movement);
        } else if (cardType === "chapter") {
          setCurrentChapter(card.chapter);
          // console.log("setCards - chapter - setCurrentChapter: " + card.chapter);
          setCurrentMovement(card.movement_start?card.movement_start:1);
          // console.log("setCards - chapter - setCurrentMovement: " + card.movement_start);
        }
      }
    }

    // Animated loader
    if (isPending) return ( <>
      {/* Center the loader */}
      <div style={{"position": "absolute", left: "50%"}}>
      <div style={{"position": "relative", left: "-50%"}}
          className="slider-loader"></div>
      </div>
      </>
    )
    
    // Error Handling
    let message = "";
    if (isError) {
      message = "Error fetching books.";         
      if (error && error.response && error.response.status && [403,404,500].includes(error.response.status)) {
        message = "Select a Language and Bible from the menu to view the books."
      }
      if (error && error.message) {
        if (error.message.includes("Network Error")) {
          message = "Unable to connect to the server. Please check your internet connection."
        } else {
          message = error.message;
        }
      }

      return (
        <div className='card-carousel'>
          <DisplayMessage message={message} />
        </div>
      )

    }

    if (!isPending && !isError && cards) {
      // console.log("CardSlider2 cardType: " + cardType);
      const chapterMode = cardType === "chapter";
      // console.log("CardSlider2 chapterMode: " + chapterMode);
      const codeName = (chapterMode)?"chapter":"movement";
      // console.log("CardSlider2 codeName: " + codeName);
      const currentCard = (chapterMode)?currentChapter:currentMovement;
      // console.log("CardSlider2 currentCard: " + currentCard);
      let index = cards.findIndex(card => card[codeName] === parseInt(currentCard));  
      //console.log("CardSlider2 index: ", index);
      let firstSlide = 0;
      if (index >= 0) {firstSlide = index}
      let cardIndex = 0;
      let cardList = [];
      // cardListSync is used to keep movement and chapter in sync; not applicable for other card types
      // When in movements, the sync will point to the first chapter in the movement.
      // When in chapters, the sync will point to the corresponding movement where the chapter starts.
      let cardListSync = []; 
      let cardImages = [];
      let cardCode = "";
          
      return (    
        <div className='card-carousel'>
          <Swiper 
              modules ={[Pagination, Navigation, EffectCoverflow]}
              initialSlide={firstSlide}
              //slidesPerView={'auto'}
              slidesPerView={5}
              spaceBetween={0}
              grabCursor={true}
              centeredSlides={true}
              slideToClickedSlide={true}
              effect={'coverflow'}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 150,
                modifier: 2.5,
              }}
              pagination={{ el: '.swiper-pagination', clickable: true }}
              navigation={{
                clickable: true,
              }}
              onSlideChange={() => {          
                if (swiper?.realIndex !== undefined && swiper.realIndex !== null) {
                  // console.log('Swiper - onSlideChange - realIndex:', swiper.realIndex, 'cardList: ', cardList);
                  // console.log('Swiper - onSlideChange - cardList: ', cardList[swiper.realIndex]?cardList[swiper.realIndex]:null);
                  // console.log('Swiper - onSlideChange - cardListSync: ', cardListSync[swiper.realIndex]?cardListSync[swiper.realIndex]:null);
                  if (cardType === "chapter") {
                    setCurrentChapter(cardList[swiper.realIndex]?cardList[swiper.realIndex]:null);
                    // console.log('Swiper - onSlideChange - setCurrentMovement: ', cardListSync[swiper.realIndex]?cardListSync[swiper.realIndex]:null);
                    setCurrentMovement(cardListSync[swiper.realIndex]?cardListSync[swiper.realIndex]:null);
                  } else {
                    // console.log('Swiper - onSlideChange - setCurrentMovement: ', cardList[swiper.realIndex]?cardList[swiper.realIndex]:null);
                    setCurrentMovement(cardList[swiper.realIndex]?cardList[swiper.realIndex]:null);
                    setCurrentChapter(cardListSync[swiper.realIndex]?cardListSync[swiper.realIndex]:null);
                  }
                  setCurrentImage(cardImages[swiper.realIndex]?cardImages[swiper.realIndex]:null);
                }}
              }
              //onSwiper={setSwiper}
              onSwiper={(swiperInstance) => {
                // console.log("Swiper instance set:", swiperInstance);
                localSetSwiper(swiperInstance);
            }}
              className="swiper-container"
            >
            {cards && cards.map(card => 
              {
                // console.log("swiper - card: ", card);
                cardCode = ((chapterMode)?card.chapter:card.movement).toString();
                cardList[cardIndex] = cardCode;
                cardListSync[cardIndex] = ((chapterMode)?(card.movement_start?card.movement_start:0):(card.chapter_start?card.chapter_start:0)).toString();
                //cardImages[cardIndex] = card.images[0]?card.images[0].url?card.images[0].url:currentImage:currentImage;
                cardImages[cardIndex] = card.images[0]?card.images[0].url?card.images[0].url:currentBookImage:currentBookImage;
                return (
                  <SwiperSlide data-hash={cardCode} key={cardCode}>
                    <div className="card-carousel-image" key={`${cardType}-${cardCode}`}>
                        <ElementCard   
                        cardCode={cardCode}   
                        cardType={cardType}
                        key={`${sourceId}-${cardType}-${cardCode}`}
                        cardName={card.title}
                        cardIndex={cardIndex++}
                        cardChapter={(chapterMode)?card.chapter:card.chapter_start}
                        cardMovement={(chapterMode)?card.movement_start:card.movement}
                        //cardDuration={card.duration}                
                        cardImageClassName={`card-image-${VIEWSTATE_CAROUSEL}`}
                        cardImage={card.images[0]?card.images[0].url?card.images[0].url:currentBookImage:currentBookImage}
                        cardAudioTitle={card.title_audio?card.title_audio[0]?.url?card.title_audio[0]?.url:null:null}
                        cardState={VIEWSTATE_CAROUSEL}
                        overlapGroupClassName="carousel-card-instance"
                        subheadingClassName="element-card-subheading"
                        showDownload={false}
                        showAddToPlayList={false}
                        showPlay={false}
                        iconAfter={true}
                        setCurrentCardAction={() => setCards(cardType,card)}
                        imageOverlay={card.images[0]?(card.images[0].url?null:((chapterMode)?card.chapter:" ")):((chapterMode)?card.chapter:" ")}
                      />              
                    </div> 
                  </SwiperSlide>
                );               
              }
            )}
            {/* Position the slider dots below the slider */}
            <div className="slider-controler">
              <div className="swiper-pagination"></div>
            </div>
          </Swiper>
        </div>        
      );
    } else {
      return null;
    }
}

CardSlider.propTypes = {
  cardType: PropTypes.oneOf(["movement","chapter",BOOK_TYPE_RESOURCES])
};