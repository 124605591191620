import React from "react";
import { SpeakerAnimate } from "../SpeakerAnimate/index.js";
import { RxDownload } from "react-icons/rx";
import { ObtQuickPlay } from "../ObtQuickPlay";
import { AppData } from "../AppContext/index.jsx";
import { PlayListData } from "../AudioPlayListContext";
import { PlayListAdd } from "../PlayListAdd";
import { setAudioPassage } from "../../utils/setAudioPassage.js";
import { addToPlayList } from "../../utils/playlistFunctions.js";
import { trackCardStart } from "../../utils/analytics.js";
import "./style.css";

// This component applies only to OBT Helps
// It displays a single audio card representing a section from ResourceSections
//
//    On click the audio will be added to the global playlist and will begin playing.
//
//    OBT Resource Hierarchy:
//    Home -> Resources (books) -> ResourceCard -> ResourceChapters -> ResourceLevel1 -> ResourceSections -> ResourceLevel2
// 
export const ObtResourceLevel2 = ( {    
    className="resource-level2-instance",
    cardMode="small",
    cardAnimation=null,  // used for placeholder shimmer on loading
    cardBookId=null,
    cardId=null,
    cardType=null,
    cardName = "",
    cardIndex=null,
    cardParent=null,  // name of the movement or chapter
    cardSection=null,
    cardSectionId=null,
    cardImage,
    cardColor,
    cardAudioTitle = "",
    cardDuration="",
    cardPassageAudio=[],
    cardPassageFileName=null,
    cardPassage=null,
    cardCategory=null,
    cardCategoryId=null,
    cardNotes=null,
    playAudioOnClick=false,
    setCurrentCardAction=null,
    showPlay=false,
    showCard=false,
    showDownload=false,
    downloadAction=null,
    showAddToPlayList=false
}) => {
  
  const { currentBook, setCurrentTrack, trackIndex, setTrackIndex, setCurrentSection, setAudioLoading, setIsOpenControls, enableControls } = AppData();  
  
  // Use a placeholder image unless this is an animation card
  const thisImage = !(cardAnimation||cardImage)?process.env.REACT_APP_PLACEHOLDER_IMAGE:cardImage;
  const cardAnimationClass = cardAnimation?cardAnimation:"";

  const clickDownload = (event) => {
    const thisDownload = downloadAction?downloadAction():null;
    if (thisDownload) {
      thisDownload();
    }
    event.stopPropagation();
    
  }

  const { dispatch, captureTrackEnd, startTime } = PlayListData();

  /**
   * 
   * @param {*} event 
   * 
   * Adds the current card to the playlist, then sets the audio passage to begin playing
   */
  const quickPlayAction = (event) => {
        
    if (playAudioOnClick ) { 
      if (cardPassageAudio) {
        let {playIndex} = addToPlayList(   
              {
                cardId:cardId,
                cardType:cardType,
                cardMode:null,
                cardBook:currentBook,
                cardParent:cardParent,
                cardSection:cardSection,
                cardName:cardName,
                cardPassageAudio:cardPassageAudio,
                cardPassageFileName:cardPassageFileName,
                cardPassage:cardPassage,
                cardCategory:cardCategory,
                cardCategoryId:cardCategoryId,
                cardDuration:cardDuration,
                cardImage:cardImage,
                cardAudioTitle:cardAudioTitle,
                cardColor:cardColor,
                showCard:true,
                overrideDelete:true,
                cardNotes:cardNotes,
                dispatch:dispatch
              }
            );

        const setCurrentCard = setCurrentCardAction?setCurrentCardAction():null;
        if (setCurrentCard) {
          (playIndex !== null)?setCurrentCard(playIndex):setCurrentCard(cardName);
        }
        captureTrackEnd("track-change", trackIndex, startTime);
        trackCardStart("click", currentBook, null, cardType, cardCategory, cardParent, cardSection, cardName);
        setAudioPassage(cardPassageAudio,playIndex,cardSection,setCurrentTrack,setTrackIndex,setCurrentSection,setAudioLoading);
        enableControls.current = true;
        setIsOpenControls(true);
      }      
    }    
    event.stopPropagation();
  }

  let cardStyle = {};
  if (cardColor) {
    // Append opacity to the color - must use 2-digit hex "alpha" value to append to the #RRGGBB value: 75% = 0xBF; 80%=0xCC
    // The alpha value AA is a hex representation of 0-100 where 0 = 00 and 100 = FF.   round(AA / 100) * 255 - then convert to hex
    let color = cardColor + 'BF' 
    cardStyle = { borderColor: color, borderStyle: "solid" }
  }

  return (
    <div className={`resource-level2 ${className} card-mode-${cardMode}`}>
      <div className="level2-frame-card" >
          <div className="level2-frame-overlay">
            <div className={`level2-frame-image` } style={cardStyle}>
              {thisImage && <img className={`resource-level2-image ${cardMode}`} alt="resource-level2" src={thisImage} />}
            </div>
            
            <div className={`level2-frame-title ${cardAnimationClass}`} >
              <SpeakerAnimate className={`title-icon-img ${cardAnimationClass}`} cardAudioTitle={cardAudioTitle} cardName={cardName}/>
              <div className="level2-card-name">{cardName}</div>              
            </div>
            
            <div className="level2-expand-wrapper">
              {showAddToPlayList && <PlayListAdd className={`level2-playlist-add`}
                  cardId={cardId}
                  cardType={cardType}
                  cardMode={null}
                  cardBook={currentBook}
                  cardParent={cardParent}
                  cardSection={cardSection}
                  cardName={cardName}
                  cardPassageAudio={cardPassageAudio}
                  cardPassageFileName={cardPassageFileName}
                  cardPassage={cardPassage}
                  cardCategory={cardCategory}
                  cardCategoryId={cardCategoryId}
                  cardDuration={cardDuration}
                  cardImage={cardImage}
                  cardAudioTitle={cardAudioTitle}
                  cardColor={cardColor}
                  showCard={showCard}
                  cardNotes={cardNotes}
                  overrideDelete={true}
                />}
              {showDownload && <RxDownload className={`level2-resource-download `} fill="rgb(235 241 249 / 50%)" onClick={(e) => clickDownload(e)}/>}
              {showPlay && <ObtQuickPlay className={`level2-resource-quickplay `} stateProp="default" duration={cardDuration} playAction={quickPlayAction}/>}
            </div>
          </div>
      </div>
    </div>
  );
};
