import axiosClient from "./axios.js"
import { useQuery } from '@tanstack/react-query';
import { getModeParm }  from './getModeParm';

export const GetLanguages = (isOBTHelps, includeProxyAPI=false, queryEnabled=true) => {
    const helpsPath = isOBTHelps?'/helps' : '';
    let includeBibleBrain = includeProxyAPI;
    const isEnabled = queryEnabled; // Only run when the query is enabled from caller
    let languages = [];
    let {modeParm} = getModeParm();
    let thisURL = "";
    if (includeBibleBrain) {
       thisURL = `${process.env.REACT_APP_PROXY_URL}${helpsPath}/languages${modeParm}`;
    } else {
       thisURL = `${process.env.REACT_APP_BASE_URL}${helpsPath}/languages${modeParm}`;
    }
    // console.log("GetLanguages: " + thisURL);
    const { data, isPending, isError, error, isFetching, isFetched } = useQuery({
        retry: false, // Disable retries
        queryKey: ['languages'],
        queryFn: () => axiosClient.get(thisURL).then((res) => res.data),
        enabled: isEnabled,
        staleTime: 1000 * 60 * 30, // cache for 30 minutes
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });  

    // if (!isPending && data) {
    //   languages = data.data;        
    // }
    if (!isPending && data) {      
      languages =  data.data;
      if (languages.length > 1) {
        languages.sort((a, b) => (a.name?.toUpperCase() > b.name?.toUpperCase())?1:-1);
      }
    }

  if (isFetching || !isFetched) {
    // console.log("GetLanguages - isPending:" , isPending, "queryEnabled:" , isEnabled, "isFetching:" , isFetching, "isFetched:" , isFetched, "languages:" , languages);
  }
  if (isError) { 
    console.log("GetLanguages - isError:" , isError, "error:" , error);
  }
  return { languages, isPending, isError, error }
}
