import React, { useEffect, useState, useRef } from "react";
import useWindowWidth from "../../hooks/useWindowWidth";
import { trackPage } from "../../utils/analytics.js";
import { ObtHeader } from "../../components/ObtHeader";
import { ObtFooter } from "../../components/ObtFooter";
import { ObtViewButtons } from "../../components/ObtViewButtons";
import { CopyrightOverlay } from "../../components/CopyrightOverlay";
import { StoryMode } from "../../components/StoryMode";
import { Sections } from "../../components/Sections";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { CardSlider } from "../../components/CardSlider";
import { GetAllSections } from "../../utils/GetAllSections";
import useFileDownloader from "../../hooks/useFileDownloader";
// import { Portal } from "../../components/Portal";
import { AudioPlaylist } from "../../components/AudioPlaylist";
import { DisplayMessage } from "../../components/DisplayMessage/DisplayMessage";
import { AppData } from "../../components/AppContext";
import { STORY_MODE, BOOK_TYPE_RESOURCES, BOOK_TYPE_EXTRAS } from '../../constants.js';
import "./style.css";

export const SectionsPage = () => {
  const { dataBreakpointsMode } = useWindowWidth();

  const { storyMode, currentBook, currentBookName, currentMovement, 
        currentMovementIds, currentMovementNames, currentChapter, currentChapterNames,
        currentResource, navigateFrom, 
        bookType, fileDownLoader,
        showPlayList, isOpenPlayer, isOBTHelps} = AppData();
  
  const isStoryMode = useRef(bookType === BOOK_TYPE_EXTRAS || (storyMode === STORY_MODE));  // Treat extras always as story mode, no chapters
  const isResourceMode = useRef(navigateFrom === BOOK_TYPE_RESOURCES);        
  const parentCode = useRef(isResourceMode.current?currentMovementIds[currentResource]:isStoryMode.current?currentMovementIds[currentMovement]:currentChapter);
  const parent = useRef((isResourceMode.current || isStoryMode.current)?"movements":"chapters");

  // For file downloads
  const [downloadFile, downloaderComponentUI] = useFileDownloader(); 
  fileDownLoader.current = downloadFile;
 
  
  useEffect(() => {
    // console.log("===> SectionsPage - currentMovement", currentMovement);
    isStoryMode.current = (bookType === BOOK_TYPE_EXTRAS) || (storyMode === STORY_MODE);
    isResourceMode.current = (navigateFrom === BOOK_TYPE_RESOURCES);
    parentCode.current = isResourceMode.current?currentMovementIds[currentResource]:isStoryMode.current?currentMovementIds[currentMovement]:currentChapter;
    parent.current = (isResourceMode.current || isStoryMode.current)?"movements":"chapters";
  }, [bookType,storyMode,navigateFrom,currentMovement,currentMovementIds,currentChapter,currentResource]);

  
  const sliderType = isResourceMode.current ? BOOK_TYPE_RESOURCES : (isStoryMode.current?"movement":"chapter") ;  
  const cardType = isResourceMode.current ? BOOK_TYPE_RESOURCES : "section"; 
  const { sourceId, sections, notes, isPending, isError, error } = GetAllSections(currentBook, parent.current, parentCode.current, currentResource, navigateFrom, bookType, isOBTHelps);
  const classPlayList = showPlayList ? "with-playlist" : "";
  // Temp for testing loading effects ...
  // let isPending = true, sourceId="JON", sections = [], isError = false, error = null;
  const parentValue = `${parent.current}-${isStoryMode.current?currentMovement:currentChapter}`;
  const localHeading = isStoryMode.current ? currentMovementNames[currentMovement] : currentChapterNames[currentChapter];
  //console.log("localHeading: ", localHeading);
  const localHeadingName = useRef(localHeading);
  const [headingName, setHeadingName] = useState(localHeading);


  useEffect(() => {
    isStoryMode.current = (bookType === BOOK_TYPE_EXTRAS) || (storyMode === STORY_MODE)
    // console.log("useEffect - isStoryMode.current: ", isStoryMode.current);
    // console.log("useEffect - currentMovement: ", currentMovement);
    const thisMovement = currentMovement?currentMovement:1;
    localHeadingName.current = isStoryMode.current ? currentMovementNames[thisMovement] : currentChapterNames[currentChapter];
    // console.log("useEffect - localHeadingName.current: ", localHeadingName.current);
    setHeadingName(localHeadingName.current);
  }, [storyMode , bookType, currentMovementNames, currentMovement, currentChapterNames, currentChapter]);

  useEffect(() => {
    // Trigger page metrics when mode changes
    const thisPage = "sections";    
    trackPage(thisPage, storyMode, currentBook, parentValue, localHeadingName.current);
  }, [storyMode, currentBook, parentValue]);
  
  // Error Handling
  let message = "";
  if (isError) {
    message = `Unable to fetch ${parent.current} for ${currentBookName}`;
    if (error && error.response && error.response.status && [403,404,500].includes(error.response.status)) {
      message = "Select a Language and Bible from the menu to view the books."
    }
    if (error && error.message) {
      if (error.message.includes("Network Error")) {
        message = "Unable to connect to the server. Please check your internet connection."
      } else {
        message = `Unable to fetch ${parent.current} for ${currentBookName}: ${error.message}`;
      }
    }
  }


  return (
    <>
    <div className={`sections-page background-wallpaper ${dataBreakpointsMode} ${classPlayList}`} data-breakpoints-mode={dataBreakpointsMode}>

      <div className="left-wrapper">
        <div className="hero-header-section">
          <ObtHeader
            breakpoint={dataBreakpointsMode}
            className="obt-header-navigation-menu"
          />
        </div>
        
        <div className={`frame-tabs-container ${dataBreakpointsMode}`}>
          <div className={`frame-tabs frame-tabs-${dataBreakpointsMode}`}>        
            <Breadcrumbs page="sections"/>

            <div className="horizontal-tabs">          
              {cardType === "section" && <StoryMode className="story-mode-toggle" />}
              
              <div className="button">
                <ObtViewButtons className="obt-view-buttons" />
              </div>
            </div>
          </div>
        </div>

        <div className="frame-title-container">
          <div className={`heading-4 ${dataBreakpointsMode}`}>{headingName}</div>
        </div>

        <div className="main-content-area">
          <div className="left-frame" >
            
            {!isError && <CardSlider cardType={sliderType} />}
                                  
            {isError && <DisplayMessage message={`${message}`}/>}

            {!isError && 
              <Sections
                className="sections" 
                cardType={cardType}
                bookId={sourceId} 
                isPending={isPending}
                parent={parent.current}
                parentCode={parentCode.current}
                sections={sections}
                notes={notes}
                downloadFile={downloadFile}/>
            }
          </div> 
        </div>

        {!isError && <CopyrightOverlay />}

        <div className="obt-footer">        
          <div className="container-footer">
              <ObtFooter  breakpoint={dataBreakpointsMode} />
          </div>
        </div>
      </div>

      <div className="right-wrapper" >
        {dataBreakpointsMode === "desktop" && showPlayList &&
          <>
            {/* {isOpenPlayer && 
              <Portal setIsOpen={setIsOpenPlayer} dimensions={dimensions}>
                <AudioPlaylist 
                  bookId={sourceId}
                  dataBreakpointsMode={dataBreakpointsMode}                  
                  />       
              </Portal>
            } */}
            
            {!isOpenPlayer && 
              <div className="right-frame">
                <AudioPlaylist 
                  bookId={sourceId}
                  dataBreakpointsMode={dataBreakpointsMode}
                  />   
              </div>
            }
          </>
        }

        {dataBreakpointsMode !== "desktop" && showPlayList &&
          <AudioPlaylist 
          bookId={sourceId}
          dataBreakpointsMode={dataBreakpointsMode}
          />   
        }
        
      </div>
      {downloaderComponentUI}
    </div>

    <div>
      <br/>
      <br/>
      <br/>
    </div>

    </>
  );
};
