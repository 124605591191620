import React from "react";
import { AppData } from "../../components/AppContext";
import "./style.css";

export const BibleInfo = ( { className="" } ) => {
  const { currentBible } = AppData();  
  const bibleName = currentBible?.name ?? "";
  const isSEB = currentBible?.id?.substring(0, 6) === "ENGSEB";
  const isESV = currentBible?.id?.substring(0, 6) === "ENGESV";

  // TBD:  Replace this with Bible info from API
  // Before using this componenet, the endpoint /bibles/:bible_id should save details from "description" and "copyright" in settings
  // Temporarily include details for ENGSEB but leave blank for other bibles until the endpoint provides the information
  let bible = {copyright:"Copyright information not available.", description:""};
  if (isSEB) {
    bible.description = "The Spoken English Bible® (SEB®) is a new translation of the Bible that is intended to be heard and told. " + 
"It is being produced from the original languages and is intended to maximize retention while still being faithful to the meaning. " +
"Listen to our podcast series for more information about this translation and to stay up to date with future developments. " +
"The SEB® is owned and produced by SIL International. SIL International is a global, faith-based nonprofit that works with local communities around the world to develop language solutions that expand possibilities for a better life.";
  }
  if (isESV) {
    // TBD: This should come from the Bible Brain API via proxyserver
    bible.description = "The English Standard Version (ESV) is an English translation of the Christian Bible. " +
    "It is a revision of the 1971 edition of the Revised Standard Version. The translators' stated purpose was to follow an \"essentially literal\" translation philosophy.";
    bible.copyright = "The ESV Bible® (The Holy Bible, English Standard Version®) Copyright © 2001 by Crossway, a publishing ministry of Good News Publishers. ESV® Text Edition: 2007. All rights reserved. " +
                "\nEnglish Standard Version, ESV, and the ESV logo are registered trademarks of Good News Publishers.  Used by permission.\nAudio: ℗ 2009 Hosanna";
  }
  return (    
    <div className={`Bible-info ${className}`}>      
      <div className="content">
        <div className="nav-menu-item">
          {/* <img className="akuo-icon" alt="Akuo icon" src="/img/akuo-logo.png" /> */}
          <div className="title">{bibleName}</div>
          <div className="description">
            <div className="flexcontainer">
              <span className="span-wrapper">
                <>{bible.description} </>              
              </span>
            </div>
          </div>
          <div className="copyright">
            {/* There is not an option using bible.copyright to embed images or links, so inserting special code for SEB only */}
            {isSEB && <>
            <p className="copyright-text">
              <span className="span">The Spoken English Bible® (SEB®) is licensed under a </span>
                <a href="https://creativecommons.org/licenses/by-sa/4.0/" rel="noopener noreferrer" target="_blank">
                  <span className="span">Creative Commons Attribution-ShareAlike 4.0 International License</span>
                </a>
              <span className="span">.</span>
            </p>
            <img className="copyright-image" alt="Copyright" src="/img/creative-commons-image.png" />
            </>
            }            
            {!isSEB && 
              <p className="copyright-text">
                {bible.copyright}
              </p>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
