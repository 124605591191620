
import React from "react";
import { useNavigate } from 'react-router-dom';
import { ObtNavMenuItem } from "../ObtNavMenuItem";
import { AppData } from "../../components/AppContext";
import { setLanguage, setBible } from "../../utils/localSettings.js";
import { GetLanguages } from "../../utils/GetLanguages";
import "./style.css";

const placeholderCards = [  
  {
    id: "TMP1",
    name: "Getting Languages..."
  }
];

export const Languages = ({ className, onLeave=null, setMobileOpen}) => {
  
  const { currentLanguage, setCurrentLanguage, setCurrentBible,includeProxyAPI, setIncludeProxyAPI, isOBTHelps } = AppData();
  let { languages, isPending, isError } = GetLanguages(isOBTHelps, includeProxyAPI);
  const navigate = useNavigate();
  
  const selectLanguage = ( language ) => {
    // Expects language parm to be in the format {iso:string, name:string}
    //console.log("setLanguage", language); 
    if (language !== undefined && language !== null) {
      let iso = language?language.iso:null;
      if (iso) {
        if (!currentLanguage || currentLanguage.iso !== language.iso) {
          setCurrentLanguage(language);
          setLanguage(language);
          // Reset Bible when switching languages
          setCurrentBible(null);
          setBible(null);
        }
      }
    }
    if (setMobileOpen !== undefined && setMobileOpen !== null) {setMobileOpen(false);}
    navigate('/home');  // when language changes, the user must select a new bible - restart from Home page.
    onLeave();
  }

  return (
      <div className="languages-menu dropdown-menu" >
        <div className="content-2"  >

          {isError && placeholderCards.map(language => {
            // If error came from proxy, just use APM directly by clearing the proxy flag for this session
            setIncludeProxyAPI(false);
            return (
              <span className="skeleton-box" key={language.id}>
                <ObtNavMenuItem      
                  key={language.id}
                  breakpoint="desktop"
                  className="languages-menu-item"
                  stateProp="default"
                  text={language.name}
                />             
              </span>
            )
          })}

          {isPending && placeholderCards.map(language => {          
            return (
              <span className="skeleton-box" key={language.id}>
                    <ObtNavMenuItem      
                      key={language.id}
                      breakpoint="desktop"
                      className="languages-menu-item"
                      stateProp="default"
                      text={language.name}
                    />             
              </span>
            )
          })}

          {!isPending && !isError && languages && languages.map(language => {
              // console.log("language:",language);
              // TEMPORARY WORKAROUND FOR DEV BUG with "en"
              const thisLanguage = language.name==='en'?"English":language.name;
              const markCurrentLanguage = currentLanguage && currentLanguage.iso === language.iso;
              return ( 
                <ObtNavMenuItem      
                  key={language.id}
                  breakpoint="desktop"
                  className="languages-menu-item"
                  isCurrent={markCurrentLanguage}
                  stateProp="default"
                  text={thisLanguage}
                  subtext={null}
                  // subtext={language.autonym}
                  onClick={() => selectLanguage({iso:language.iso, name:thisLanguage})}
                  />
              )
            })}
        </div>      
      </div>
  );
};


