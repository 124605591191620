
import React from "react";
import { ElementCard } from "../ElementCard";
import { AppData } from "../../components/AppContext";
import { TRADITIONAL_MODE, BOOK_TYPE_EXTRAS } from '../../constants.js';
import { GetAllBooks } from "../../utils/GetAllBooks";
import { DisplayMessage } from "../../components/DisplayMessage/DisplayMessage";
import "./style.css";

const placeholderCards = [  
  {
    book_id: "TMP1",
    name: "_",
    name_alt: "_"
  },
  {
    book_id: "TMP2",
    name: "_",
    name_alt: "_"
  }
];

// Extras are "General" projects that should be treated the same way as Books 
export const Extras = ({ className }) => {
  const { viewState, storyMode, setCurrentBook, isOBTHelps } = AppData();
  const { books, isPending, isError, error } = GetAllBooks(BOOK_TYPE_EXTRAS, isOBTHelps);
  const isTraditionalMode = storyMode === TRADITIONAL_MODE;

  // Error Handling
  let message = "";
  if (isError) {
    message = "Error fetching extras.";         
    if (error && error.response && error.response.status && [403,404,500].includes(error.response.status)) {
      message = "Select a Language and Bible from the menu to view the books."
    }
    if (error && error.message) {
      if (error.message.includes("Network Error")) {
        message = "Unable to connect to the server. Please check your internet connection."
      } else {
        message = error.message;
      }
    }
  }
  
  return (
    <div className={`obtextras ${viewState} ${className}`}>
      <div className="frame-extras">   

        {isError && <DisplayMessage message={`${message}`}/>}

        {isPending && !isError && placeholderCards.map(book => {          
          return (
            <span className="skeleton-box" key={book.book_id}>
            <ElementCard
              key={book.book_id}
              cardCode={book.book_id}
              cardType={BOOK_TYPE_EXTRAS}
              cardAnimation = "shimmer"
              cardName= {isTraditionalMode ? book.name : book.name_alt}
              cardDuration="--:--"
              cardImageClassName={`card-image-${viewState}`}
              //cardImage
              cardAudioTitle={null}
              cardState={viewState}
              subheadingClassName="element-card-subheading"                                 
              showPlay={false}                   
              showDownload={false}                         
              overlapGroupClassName="instance-node"
              showAddToPlayList={false}
            />
            </span>
          )
        })}    
        

        {!isPending && !isError && books && books.map(book => {     
            return (
            <ElementCard
              key={book.book_id}
              cardCode={book.book_id}
              cardType={BOOK_TYPE_EXTRAS}
              cardName={ isTraditionalMode ? book.name : book.name_alt}
              cardDuration={book.duration?book.duration:"-:--"}
              cardImageClassName={`card-image-${viewState}`}
              cardImage={book.images[0]?book.images[0].url?book.images[0].url:null:null}
              cardAudioTitle={isTraditionalMode ? ( book.title_audio?book.title_audio[0]?.url?book.title_audio[0].url:null:null ) 
                                                : ( book.title_audio_alt?book.title_audio_alt[0]?.url?book.title_audio_alt[0].url:null:null ) }
              cardState={viewState}
              subheadingClassName="element-card-subheading"                                 
              showPlay={false}    // TBD: Enable play from book
              obtQuickPlayClassName={`card-quickplay-${viewState}`}
              showDownload={false}  // TBD: Enable download book         
              obtDownloadClassName={`card-download-${viewState}`}
              overlapGroupClassName="instance-node"
              navigateTo="/movements"
              setCurrentCardAction={() => setCurrentBook}
              showAddToPlayList={true}
            />
            )
          })}
      </div>      
    </div>
  );
}

