// Set MVP and mode parameters to determine which API source to use
// Returns the text to be added to the API for ?beta if true
export const getModeParm = () => {
    let modeItem = "";
    let isMVP = process.env.REACT_APP_MODE === 'mvp';

    // Check for an updated parameter in the URL
    const queryString = window.location.search;
    // console.log("queryString", queryString);
    const urlParams = new URLSearchParams(queryString);
    const betaParm = urlParams.get('beta')
    // console.log("betaParm", betaParm);
    if (betaParm !== undefined && (betaParm === "false" || betaParm === "true")) {
        modeItem = betaParm;
        sessionStorage.setItem("betaMode", betaParm)
    } else {
        // If no parm use the session value
        modeItem = sessionStorage.getItem("betaMode");
    }    
    
    const modeParm = modeItem === "true" ? "?beta=true" : isMVP?"?mvp=true":"";
    const isBeta = modeItem === "true";
    const isHelps = process.env.REACT_APP_DOMAIN==="obthelps.org";
    // console.log("modeParm", modeParm);
    return {isMVP, modeParm, isBeta, isHelps}
}

export const getBibleBrainParms = () => {
    const version = process.env.REACT_APP_BIBLE_BRAIN_VERSION;
    const key = process.env.REACT_APP_BIBLE_BRAIN_KEY;
    const parmString = `v=${version}&key=${key}`;
    return parmString;
}