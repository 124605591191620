
import React from "react";
import { getBible } from "../../utils/localSettings";
import "./style.css";

export const DisplayMessage = ( { className='' , title="", message=""} ) => {
  let { bibleName } = getBible();
  bibleName = bibleName?bibleName:"No Bible Selected";
  let newTitle = title?(title!==""?title:bibleName):bibleName;
  return (    
        
    <div className={`display-message ${className}`}>
      <div className="display-content">
          <div className="title">{newTitle}</div>
          <div className="description">
            <div className="flexcontainer">
                <>{message} </>
            </div>
          </div>
      </div>
    </div>
      
  );
};

