import React from "react";
import { useNavigate } from 'react-router-dom';
import { ObtNavMenuItem } from "../ObtNavMenuItem";
import { AppData } from "../../components/AppContext";
import { setBible } from "../../utils/localSettings.js";
import { GetBibles } from "../../utils/GetBibles";
import "./style.css";

const placeholderCards = [  
  {
    bible_id: "TMP1",
    name: "Getting Bibles ..."
  }
];

export const BiblesMenu = ( {onLeave=null, setMobileOpen=null} ) => {
  const { currentLanguage, currentBible, setCurrentBible, includeProxyAPI, setIncludeProxyAPI, isOBTHelps } = AppData();  
  let { bibles, isPending, isError } = GetBibles(currentLanguage?.iso, isOBTHelps, includeProxyAPI, true);
  const navigate = useNavigate(); 

  const selectBible = ( bible ) => {
    // console.log('selectBible:', bible); 
    if (bible?.bible_id) {
        let thisBible = {'id':bible.bible_id, 'name':bible.name, 'source':bible.source};
        setCurrentBible(thisBible);
        setBible(thisBible);
    }
    if (setMobileOpen !== null) {setMobileOpen(false);}
    navigate('/home');
    onLeave();
  }

  return (
    <div className="bibles-menu dropdown-menu" >
      <div className="content-2"> 

        {isError && placeholderCards.map(bible => {
          // If error came from proxy, just use APM directly by clearing the proxy flag for this session
          setIncludeProxyAPI(false);
          return (
            <span className="skeleton-box" key={bible.bible_id}>
                  <ObtNavMenuItem      
                    key={bible.bible_id}
                    breakpoint="desktop"
                    className="bibles-menu-item"
                    stateProp="default"
                    text={bible.name}
                  />             
            </span>
          )
        })}

        {isPending && placeholderCards.map(bible => {          
          return (
            <span className="skeleton-box" key={bible.bible_id}>
                  <ObtNavMenuItem      
                    key={bible.bible_id}
                    breakpoint="desktop"
                    className="bibles-menu-item"
                    // icon="/img/akuo-logo.png"
                    stateProp="default"
                    text={bible.name}
                    // subtext={language.autonym}
                  />             
            </span>
          )
        })}

        {!isPending && bibles && bibles.map(bible => {
          // console.log("language:",language);
          const markCurrent = currentBible && currentBible.id === bible.bible_id;
          return (
            <ObtNavMenuItem
              key={bible.bible_id}
              id={bible.bible_id}
              breakpoint="desktop"
              className="bibles-menu-item"
              stateProp="default"
              text={bible.name}
              subtext=""
              isCurrent={markCurrent}
              onClick={() => selectBible(bible)}
            />
          )
        })}
      </div>
    </div>
  );
};
