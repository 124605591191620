import React from "react";
import { getBibleId } from '../../utils/localSettings';
import { ObtButton } from "../../components/ObtButton";
import { PiChats } from "react-icons/pi";
import "./style.css";

export const ObtNavMenuSupport = ( {  
  breakpoint="desktop",
  className="obt-nav-menu-support-instance",
  stateProp="default",
  icon=null,
  text,
  subtext=null,
  onClick=null,
  isOBTHelps=false,
}) => {
  const bibleId = getBibleId();
  // TBD:  Use API info instead of hardcoding - bible metadata should provide - need to add to APM first 
  const bibleDetails = {obt_email: process.env.REACT_APP_OBT_EMAIL, seb_email: process.env.REACT_APP_SEB_EMAIL}
  return (    
    <div className="obt-nav-menu-support">      

      {/* TECH SUPPORT SECTION */}
      <div className="contact-box">
        <div className="contact-icon-wrapper">
          <div className="featured-icon">
            <PiChats className="obt-icon" fill="white"/>
          </div>
          <div className="contact-text">Technical Support</div>
        </div>
        <div className="contact-details">
          <div className="text-and-supporting">
            <div className="supporting-text">We are here to help!</div>
          </div>
          <ObtButton
            className="obt-button-link"
            textClassName="obt-button-link"
            hierarchy="link-color"
            iconLeading={false}
            iconTrailing={false}
            size="lg"
            stateProp="default"
            text={bibleDetails.obt_email}
          />
        </div>
      </div>

      {/* Only display for OBTHelps or SEB */}
      { (isOBTHelps || bibleId === "ENGSEB") &&          
        <div className="contact-box">
          {/* EMAIL SECTION */}
          <div className="contact-icon-wrapper">
            <div className="featured-icon">
              <PiChats className="obt-icon" fill="white"/>
            </div>
            <div className="contact-text">Email us</div>
          </div>
          <div className="contact-details">
            <div className="text-and-supporting">
              <div className="supporting-text">We appreciate your feedback on the Spoken English Bible.</div>
            </div>
            <ObtButton
              className="obt-button-link"
              textClassName="obt-button-link"
              hierarchy="link-color"
              iconLeading={false}
              iconTrailing={false}
              size="lg"
              stateProp="default"
              text={bibleDetails.seb_email}
            />
          </div>
        </div>
      }
    </div>
  );
};
