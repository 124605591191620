
import React from "react";
import { ObtNavMenuItem } from "../ObtNavMenuItem";
import "./style.css";

export const Publishing = ({ className, onLeave=null, setMobileOpen}) => {
  
    return (
      <div className="publishing-menu dropdown-menu" >
        <div className="content-2">
          <ObtNavMenuItem      
            key={1}
            breakpoint="desktop"
            className="publishing-menu-item"
            // icon=""
            stateProp="default"
            text="Akuo/APM Demo"
            subtext={null}
            onClick={() => window.open('https://www.youtube.com/watch?v=2aGGJzaOSNE', '_blank')}
            />
        </div>      
      </div>
  );
};


