import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AppLayout from "./AppLayout.js";
import { HomePage } from "./screens/HomePage/index.js";
import { MovementsPage } from "./screens/MovementsPage/index.js";
import { SectionsPage } from "./screens/SectionsPage/index.js";
import { ExtrasPage } from "./screens/ExtrasPage/index.js";
// import { WebPlayer } from "./screens/WebPlayer";

const router = createBrowserRouter([
{
  path: "/",
  element: <AppLayout />,
  children: [
    {
      path: "",
      element: <HomePage />,
    },
    {
      path: "/home",
      element: <HomePage />,
    },
    {
      path: "/movements",
      element: <MovementsPage />,
    },
    {
      path: "/chapters",
      element: <MovementsPage />,
    },
    {
      path: "/extras",
      element: <ExtrasPage />,
    },
    {
      path: "/sections",
      element: <SectionsPage />,
    }
    // ,
    // {
    //   path: "/webplayer",
    //   element: <WebPlayer />,
    // }
  ]
}
]);

export const App = () => {    
  return <RouterProvider router={router}/>;
};
