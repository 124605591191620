import PropTypes from "prop-types";
import React, { useReducer }  from "react";
import { getBibleId } from '../../utils/localSettings';
import { AppData } from "../../components/AppContext";
import "./style.css";

export const ObtToggle = ({ className, pressed, size, stateProp, color, cardIndex, categoryId, toggleCategory=null }) => {
  const bibleId = getBibleId();
  const {categoryList} = AppData();
  const [state, dispatch] = useReducer(reducer, {
    pressed: pressed || false,
    size: size || "sm",    
    state: stateProp || "default",
    colorOn: color ,
    color: color    
  });

  return (
    <div className={`obt-toggle ${size}`}>
    <div
      className={`obt-toggle-base ${state.size} ${state.state} pressed-${state.pressed} ${className}` }
      style={state.pressed?{backgroundColor: `${state.color}`}:null}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onClick={() => {
        const toggleCategoryLocal = toggleCategory?toggleCategory:null;
        if (toggleCategoryLocal) categoryList.current = toggleCategoryLocal(bibleId, categoryId);
        // console.log("toggleCategory index: ", cardIndex); 
        // console.log("toggleCategory categoryId: ", categoryId); 
        dispatch("click");
      }}
    >
      <div className="button" />
    </div>
    </div>
  );
};

function reducer(state, action) {
  if (state.pressed === true && state.state !== "disabled" ) {
    switch (action) {
      case "click":        
        return {
          pressed: false,
          size: state.size,
          state: state.state,
          color: "var(--gray-200)",
          colorOn: state.colorOn
        };
      default: return state;
    }
  }

  if (state.pressed === false && state.state !== "disabled" ) {    
    switch (action) {
      case "click":
        return {
          pressed: true,
          size: state.size,
          state: state.state,
          color: state.colorOn,
          colorOn: state.colorOn
        };
      default: return state;
    }
  }  

  switch (action) {
    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };

    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };
    default: 
      return state;
  }
  
}

ObtToggle.propTypes = {
  pressed: PropTypes.bool,
  size: PropTypes.oneOf(["md", "sm"]),
  stateProp: PropTypes.oneOf(["default", "disabled", "hover", "focus"]),
};
