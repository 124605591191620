import { getModeParm }  from './getModeParm';

// Distinguish beta mode from regular mode and set the language and bible tags accordingly
const {isBeta} = getModeParm();
const languageTag = `language${isBeta?"-beta":""}`;
const bibleTag = `bible${isBeta?"-beta":""}`;

// Returns a JSON structure on getItem and Saves the stringified JSON on setItem
// Saved as keyLabel
export const localSettings = (action, keyLabel=null, value=null) => {
    function isJsonString(str) {
        try {
            var json = JSON.parse(str);
            return (typeof json === 'object');
        } catch (e) {
            return false;
        }
    }

    if (keyLabel === undefined || keyLabel === null) {
        console.error("Missing keyLabel", keyLabel);
        return null;
    }
    
    switch (action) {
        case "setItem":
            localStorage.setItem(keyLabel, JSON.stringify(value));
            // console.log(keyLabel, value);
            return null;

        case "getItem":
            let savedSettings = [];
            let getSettings = localStorage.getItem(keyLabel);
            if (!isJsonString(getSettings)) {
                localStorage.removeItem(keyLabel);
                console.error("Not a valid JSON string", keyLabel, getSettings);
                return null;
            }
            if (getSettings) {
                savedSettings = JSON.parse(getSettings);
            }
            // console.log(keyLabel, savedSettings);
            return savedSettings;

        case "resetItem":
            localStorage.removeItem(keyLabel);
            // console.log(keyLabel, value);
            return null;
        default:
            return null;
    }
}

/**
 * 
 * @returns bibleId from the local storage
 */
export const getBibleId = () => {
    let bibleId = getLocalBible()?.id ?? null;
    // console.log("getBibleId: ", bibleId);
    return bibleId;
  }

/**
 * 
 * @returns bible object from the local storage
 */
export const getLocalBible = () => {
    let thisBible = localSettings('getItem', bibleTag);
    // console.log("getBibleId - thisBible: ", thisBible);
    return thisBible;
  }  

/**
 * 
 * @returns bibleId, bibleName, and flags to indicate bible sources isAPM and isBibleBrain based on the bible stored in local storage
 */
export const getBible = () => {
    let isAPM = false;
    let isBibleBrain = false;
    let bibleId = null;
    let bibleName = null;
    // console.log("getBible - bibleTag: ", bibleTag);
    let bible = localSettings('getItem', bibleTag);
    // console.log("getBible - bible: ", bible);
    if (bible !== undefined && bible !== null) {
        if (bible.id !== undefined) {
            bibleId = bible.id;
        }
        if (bible.name !== undefined) {
            bibleName = bible.name;
        }
        if (bible.source !== undefined) {      
            isAPM = bible.source === "APM";
            isBibleBrain = bible.source === "Bible Brain";
        }
    }
    // console.log("getBible bibleId: ", bibleId, "isAPM: ", isAPM, " - isBibleBrain: ", isBibleBrain);
    return {bibleId, bibleName, isAPM, isBibleBrain};    
  }

  export const setBible = (bible) => {
    if (bible === undefined || bible === null) {
        localSettings('resetItem', bibleTag, null);    
    } else {
        localSettings('setItem', bibleTag, bible);
    }
  }

  export const getLanguage = () => {
    let language = localSettings('getItem', languageTag);
    return language;
  }

  export const setLanguage = (language) => {
    localSettings('setItem', languageTag, language);
  }