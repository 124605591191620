
import React from "react";
import { AppData } from "../../components/AppContext";
import "./style.css";

export const LanguageLabel = ({ className }) => {
  
  const { currentLanguage } = AppData();
  const languageId = currentLanguage?.iso;
  const haveLanguage = !!languageId;  // truthy check for undefined, null, or empty string

  return (
    <>
      {haveLanguage && <div className={`language-label ${className}`} ><p className="language-text">{languageId}</p></div> }
    </>
  );
};


