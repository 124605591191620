import React, { useState } from "react";
import { RiPlayListAddLine as PlayListAddIcon} from "react-icons/ri";
import { PlayListData } from "../AudioPlayListContext";
import { PlayListAddModal } from "../PlayListAddModal";
import { addToPlayList } from "../../utils/playlistFunctions.js";
import "./style.css";
/**
 * PlayListAdd - This component will be rendered from various levels of ElementCards, based on the cardType property:
 * 
 * Add content to playlist:
 * section : adds the section and its related notes to the playlist.
 * note: adds the note to the playlist.
 * 
 * Invoke more queries to fetch sections to add to the playlist via PlayListAddModal: 
 * chapter: pops up a processing modal, queries all sections for the chapter, and adds the sections and notes to the playlist.
 * movement: pops up a processing modal, queries all sections for the movement, and adds the sections and notes to the playlist.
 * book: pops up a processing modal, queries all movements for the book, with sections=true, and adds the ections and notes to the playlist.
 * 
 */
export const PlayListAdd = ( {
    className, 
    cardId=null,
    cardType=null,
    cardMode=null,
    cardBook=null,
    cardParent=null,
    cardSection=null,
    cardName=null,
    cardPassageAudio=null,
    cardPassageFileName=null,
    cardPassage=null,
    cardCategory=null,
    cardCategoryId=null,
    cardDuration=null,
    cardImage=null,
    cardAudioTitle=null,
    cardColor=null,
    showCard=null,
    overrideDelete=false,
    cardNotes=null   // if included then add the notes related to the selected passage card
    } ) => {

  const { dispatch } = PlayListData();
  const [isOpenPlayListModal, setIsOpenPlayListModal] = useState(false);
  const [playListAddComplete, setPlayListAddComplete] = useState(false);
  const [playListAddError, setPlayListAddError] = useState(false);

  const clickActions = (event) => {
    // console.log("PlayListAdd Add cardType:", cardType, "cardSection:", cardSection, "cardId", cardId);
    if (cardType === "section" || cardType === "note") {
      addToPlayList(
        {
          cardId:cardId,
          cardType:cardType,
          cardMode:cardMode,
          cardBook:cardBook,
          cardParent:cardParent,
          cardSection:cardSection,
          cardName:cardName,
          cardPassageAudio:cardPassageAudio,
          cardPassageFileName:cardPassageFileName,
          cardPassage:cardPassage,
          cardCategory:cardCategory,
          cardCategoryId:cardCategoryId,
          cardDuration:cardDuration,
          cardImage:cardImage,
          cardAudioTitle:cardAudioTitle,
          cardColor:cardColor,
          showCard:true,
          overrideDelete:overrideDelete,
          cardNotes:cardNotes,
          dispatch:dispatch
        }
      );
  } else {
    setPlayListAddComplete(false);
    setPlayListAddError(false);
    setIsOpenPlayListModal(true);
  }

    event.stopPropagation();
  }

  // Function to pass to modal to execute on close
  function closePlayListModal() {
      // console.log("closePlayListModal()");
      //Close the dialog 
      setIsOpenPlayListModal(false);
      // Revert to scrollable page when modal closes
      document.body.style.overflow = "auto";
    }
  

  // console.log("PlayListAdd isOpenPlayListModal:", isOpenPlayListModal);

  return (
    <div className={`obt-playlist-add ${className}`}>
      <div className="obt-playlist-add-frame" >
          <div className="icon-frame">
            <span className="tooltip">Add to Playlist</span>
            <PlayListAddIcon className="obt-playlist-add-icon" onClick={(e)=>clickActions(e)}/>

            {isOpenPlayListModal && 
            <PlayListAddModal
              className="playlist-add-modal"
              cardId={cardId}
              cardType={cardType}
              cardMode={cardMode}
              cardBook={cardBook}
              cardParent={cardParent}
              cardSection={cardSection}
              cardName={cardName}
              open={isOpenPlayListModal && !playListAddComplete}
              isOpenPlayListModal={isOpenPlayListModal}
              playListAddComplete={playListAddComplete}
              setPlayListAddComplete={setPlayListAddComplete}
              playListAddError={playListAddError}
              setPlayListAddError={setPlayListAddError}
              onClose={() => {closePlayListModal()}}/>
            }
          </div>
      </div>
    </div>
  );
}
